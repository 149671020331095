<template>
  <v-main>
    <v-container>
      <v-card-title class="custom-text-4">Create New Client Account</v-card-title>
      <v-row justify="center">
        <v-col cols="12" md="12">
          <!-- Membungkus formulir dalam v-card -->
          <v-card class="pa-5">
            <v-spacer></v-spacer>
            <v-form @submit.prevent="submitForm">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="newItem.username" label="Username"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="newItem.password" label="Password" type="password"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="newItem.companyName" label="Nama Perusahaan"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="newItem.companyAddress" label="Alamat Perusahaan"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="newItem.contactPerson" label="Nama Kontak Utama"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="newItem.contactEmail" label="Email Kontak Utama"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="newItem.phoneNumber" label="Nomor Telepon"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select outlined v-model="newItem.roleCode" :items="roleCode" label="Role Code"></v-select>

                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="newItem.startDate" label="Tanggal Mulai Kerjasama"
                    type="date"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field outlined v-model="newItem.endDate" label="Tanggal Berakhir Kerjasama"
                    type="date"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-btn type="submit" color="primary">Register</v-btn>
                </v-col>
              </v-row>
            </v-form>

            <!-- Memberikan jarak dari bawah formulir -->
            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
      <ConfirmationDialog ref="confirmationDialog" />
      <DeleteDialog ref="deleteDialog" />
      <InformationDialog ref="informationDialog" />
      <LoadingDialog ref="loadingDialog" />
      <Snackbar ref="snackbar" />
    </v-container>
  </v-main>
</template>


<script>
import usereApi from "../../api/UserApi";
import auditrail from "../../api/AuditrailApi";
import Swal from 'sweetalert2';

export default {
  async mounted() {
    await auditrail.create({
      action: this.$route.path
    });
  },
  data() {
    return {
      newItem: {
        username: '',
        password: '',
        companyName: '',
        companyAddress: '',
        phoneNumber: '',
        contactPerson: '',
        contactEmail: '',
        startDate: null,
        endDate: null,
        roleCode: ['client', 'administrator'],
      },
      roleCode: ['client', 'administrator'],
      mode: 'create'
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1); // Atau logika navigasi lainnya
    },
    async submitForm() {
      this.mode = 'create';
      await this.save();
    },
    async save() {
      try {
        let result;
        if (this.mode === "create") {
          result = await usereApi.create(this.newItem);
        } else if (this.mode === "update") {
          result = await usereApi.update(this.newItem);
        }

        if (result.ErrorMessage) {
          throw new TypeError(result.ErrorMessage);
        }

        Swal.fire({
          title: 'Success!',
          text: 'Client account has been registered successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push("/client/profile"); // Navigasi ke profil klien
          }
        });
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  }
};
</script>
<style scoped>
.v-footer {
  color: white;
}

.v-main {
  padding-top: 2rem;
  /* Atur padding atas */
  padding-bottom: 2rem;
  /* Atur padding bawah */
}

.v-card {
  margin-top: 1cm;
  margin-bottom: 1cm;
}
</style>
