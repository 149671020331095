<template>
  <v-app>
    <v-main>
      <h2 class="my-4 custom-text-9-id">FAQ / Hal Yang Biasa Di Tanyakan</h2>
      <v-container>
        <v-card class="max-width-card center-card"> <!-- Tambahkan class max-width-card -->
          <v-expansion-panels>
            <v-expansion-panel v-for="(item, i) in items" :key="i" cols="12" md="6">
              <v-expansion-panel-header class="custom-text-2">{{ item.question }}</v-expansion-panel-header>
              <v-expansion-panel-content class="custom-text-5" cols="12" md="6">
                {{ item.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
export default {
  data: () => ({
    items: [
      { question: 'PERSYARATAN APA YANG DIBUTUHKAN UNTUK MENDAFTAR?', answer: 'Untuk mendaftar, Anda membutuhkan identitas diri yang sah (KTP/SIM), CV terbaru, ijazah terakhir, dan sertifikat keahlian (jika ada).' },
      { question: 'BAGAIMANA CARA MENDAFTAR DI LPK AISHIN?', answer: 'Anda dapat mendaftar melalui website resmi kami atau datang langsung ke kantor LPK Aishin Indonesia untuk mengisi formulir pendaftaran.' },
      { question: 'BERAPA LAMA PROGRAM PELATIHAN DI LPK AISHIN?', answer: 'Durasi program pelatihan bervariasi, tergantung pada program spesifik, mulai dari 3 bulan hingga 1 tahun.' },
      { question: 'APA SAJA PROGRAM PELATIHAN YANG DITAWARKAN OLEH LPK AISHIN?', answer: 'LPK Aishin menawarkan berbagai program pelatihan, termasuk keahlian teknis, bahasa asing, dan keterampilan manajerial.' },
      { question: 'BISAKAH SAYA BEKERJA DI JEPANG SETELAH MENGIKUTI PELATIHAN?', answer: 'Ya, kami menyediakan jalur untuk bekerja di Jepang bagi peserta yang berhasil menyelesaikan program pelatihan tertentu dan memenuhi kriteria.' },
      { question: 'APA SAJA MANFAAT MENGIKUTI PELATIHAN DI LPK AISHIN?', answer: 'Manfaat mengikuti pelatihan termasuk sertifikasi keahlian, dukungan penempatan kerja, dan akses ke jaringan profesional kami.' },
      { question: 'BERAPA BIAYA UNTUK MENGIKUTI PROGRAM PELATIHAN?', answer: 'Biaya program pelatihan berbeda-beda tergantung pada kursus yang dipilih. Informasi lebih lanjut dapat hubungi kami langsung.' },
      { question: 'APAKAH LPK AISHIN MENYEDIAKAN BEASISWA ATAU BANTUAN KEUANGAN?', answer: 'Ya, kami menawarkan beasiswa dan bantuan keuangan bagi peserta yang memenuhi syarat. Silakan hubungi kami untuk informasi lebih lanjut.' },
      { question: 'BAGAIMANA PROSES SELEKSI PESERTA PELATIHAN?', answer: 'Proses seleksi meliputi evaluasi dokumen, tes kemampuan, dan wawancara. Detail proses seleksi dapat hubungi kami melalui email atau telp.' },
      { question: 'DIMANA SAYA BISA MENEMUKAN INFORMASI LOWONGAN KERJA SETELAH PELATIHAN?', answer: 'Informasi lowongan kerja dapat ditemukan di papan pengumuman kami, website resmi, atau melalui newsletter email yang kami kirimkan kepada alumni.' },
    ],

  }),
};
</script>
<style>
.max-width-card {
  max-width: 800px;
}
.center-card {
  margin: 0 auto;
}
</style>