<template>
    <v-container fluid>
        <div class="my-4 custom-text-9-id">FILOSOFI PERUSAHAAN</div>
        <v-row>
            <v-col cols="12" sm="4" class="text-right">
                <v-avatar size="250px" style="padding-right: 20px; border-radius: 12px;">
                    <img src="/images/teachers/berman.webp" alt="President-Director Berman Nainggolan"
                        style="border-radius: 12px;">
                </v-avatar>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card flat>
                    <v-card-text class="custom-text-2 text-justify">
                        <p> "Pemuda Indonesia memiliki impian. Yang pertama adalah bekerja... Di Indonesia, di mana
                            tingkat pengangguran di kalangan orang di bawah usia 25 tahun mencapai hampir 20%, banyak
                            generasi muda ingin bekerja.</p>

                        <p> Yang kedua adalah mewujudkan impian saya... Berkeliling Jepang, yang kaya akan alam,
                            mengunjungi tempat-tempat anime suci, dan membeli barang di Akihabara.</p>

                        <p> Budaya anime Jepang telah meresap ke dalam pemuda Indonesia. Dan terakhir, "membangun masa
                            depan"... Para pemuda yang pergi ke Jepang bermimpi untuk mendapatkan pekerjaan atau memulai
                            bisnis di perusahaan Jepang berdasarkan pengalaman kerja mereka di Jepang.</p>

                        <p> Filosofi manajemen LPK AISHIN INDONESIA adalah untuk membantu masyarakat mewujudkan impian
                            mereka. Misi kami adalah membuka jalan menuju masa depan yang aman bagi sebanyak mungkin
                            generasi muda."</p>

                        <p> - Berman Nainggolan</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <div class="custom-text-9-id">EKSEKUTIF PERUSAHAAN</div>
        <v-row class="my-4">
            <v-col v-for="person in people" :key="person.name" cols="12" md="12" class="text-center">
                <v-card flat>
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="3">
                            <v-avatar size="150" class="ma-3" style="border-radius: 12px;">
                                <img :src="person.photoUrl" alt="Photo" style="border-radius: 12px;">
                            </v-avatar>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-text class="text-left">
                                <div class="mb-1 custom-text-3-id text-justify">{{ person.name }}</div>
                                <div class="mb-1 custom-text-3-id text-justify">{{ person.position }}</div>
                                <div class="mb-1 custom-text-2-id text-justify">{{ person.description }}</div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
    async mounted() {
        await auditrail.create({
            action: this.$route.path
        });
    },
    data() {
        return {
            people: [
                {
                    photoUrl: '/images/teachers/berman.webp',
                    name: 'Berman Nainggolan',
                    position: 'Ketua',
                    description: 'Berman Nainggolan, seorang ahli hukum dengan gelar SH., MH., CLA, telah mendedikasikan karirnya untuk advokasi keadilan. Sebagai Ketua LPK AISHIN INDONESIA, Berman tidak hanya bertanggung jawab atas kepemimpinan tetapi juga menentukan visi perusahaan dengan rasa keadilan yang kuat. Bersertifikat JLPT N1, keahlian bahasa Jepangnya memperkuat kolaborasi lintas budaya dalam mencapai tujuan bersama.'
                },
                {
                    photoUrl: '/images/teachers/kato.webp',
                    name: 'Kousuke Kato',
                    position: 'Direktur Perwakilan EBT Co., Ltd.',
                    description: 'Kousuke Kato, Direktur Perwakilan EBT Co., Ltd., adalah jembatan antara Jepang dan Indonesia. Dengan latar belakang dalam hubungan internasional dan pengalaman yang luas dalam memfasilitasi kerjasama lintas batas, Kousuke mewujudkan visi perusahaan dengan komitmen yang tak tertandingi. Sebagai manajer proyek strategis, kemampuannya untuk mengubah visi menjadi kenyataan telah mendapatkan pengakuan luas.'
                },
                {
                    photoUrl: '/images/teachers/yosua.webp',
                    name: 'Yosua Parmes Sitorus',
                    position: 'Koordinator',
                    description: 'Yosua Parmes Sitorus, seorang koordinator dengan visi tajam dan keahlian yang mendalam. Lulusan Universitas Tokyo dan bersertifikasi JLPT N1, Yosua membawa keterampilan komunikasi lintas budaya yang penting ke perannya. Dalam memimpin berbagai inisiatif, Yosua berkomitmen untuk membangun jaringan yang kuat dan memastikan kesuksesan bersama bagi semua pihak yang terlibat.'
                },

            ],
        };
    }
};
</script>

<style>
.custom-text-4-id {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 900;
    font-size: 50px;
    color: #900C27;
    text-align: justify;
    padding-left: 250px;
}

.custom-text-3-id {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #900C27;
    text-align: justify;
}

.custom-text-2-id {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
    line-height: 1.5;
}
.custom-text-9-id {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 52px;
  color: #900C27;
  text-align: center;
  padding-bottom: 10px;
  line-height: 1.5;
}

</style>
