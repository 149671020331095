<template>
  <v-dialog v-model="visible" width="450" @keyup.esc="tidak()">
    <v-card>
      <div class="pa-5 text-h6 grey--text text--darken-1">
        <v-icon>mdi-help-box</v-icon>
        {{ title }}
      </div>

      <div class="px-5 pb-3" style="white-space: pre-line;">
        <p>{{ message }}</p>
      </div>

      <div class="pa-3 text-right">
        <v-btn class="mr-2" text @click="tidak()">
          <v-icon left>mdi-close</v-icon>
          NO
        </v-btn>
        <v-btn color="primary" min-width="110px" @click="ya()">
          <v-icon left>mdi-check</v-icon>
          YES
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  data() {
    return {
      visible: false,
      title: "",
      message: "",
      resolve: null,
      reject: null,
    };
  },
  methods: {
    show(message = "Anda yakin?", title = "Konfirmasi") {
      this.visible = true;
      this.title = title;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ya() {
      this.resolve(true);
      this.visible = false;
    },
    tidak() {
      this.resolve(false);
      this.visible = false;
    },
  },
};
</script>
