<template>
  <div>
    <span v-if="value">YES</span>
    <span v-if="!value">NO</span>
  </div>
</template>

<script>
export default {
  props: {
    value : {
      type: [Boolean],
      default: false
    }
  }
}
</script>