import http from "./http";

const UserApi = {
    endPoint: "api/user",

    async getAll() {
        const response = await http.get(this.endPoint);
        return response.data;
    },

    async getStamp(id) {
        const response = await http.get(this.endPoint + `/stamp/${id}`);
        return response.data;
    },

    async get(id) {
        const response = await http.get(this.endPoint + `/${id}`);
        return response.data;
    },

    async getRequest(id) {
        const response = await http.get(this.endPoint + `/request/${id}`);
        return response.data;
    },
    
    async getList() {
        console.log(this.endPoint)
        const response = await http.get(`${this.endPoint}/GetList`);
        return response.data;
    },
    async create(resource) {
        const response = await http.post(this.endPoint + `/create`, resource);
        return response.data;
    },

    async update(resource) {
        const response = await http.put(this.endPoint + `/${resource.id}`, resource);
        return response.data;
    },

    async delete(id) {
        await http.delete(this.endPoint + `/${id}`);
    }
};

export default UserApi;

