<template>
    <v-container>
      <!-- Bagian Promosi Pendidikan di LPK Aishin -->
      <v-row>
        <v-col cols="12">
          <v-card class="text-center" color="teal" dark>
            <v-card-title class="display-1">Jelajahi Peluang di LPK Aishin</v-card-title>
            <v-card-text>
              Gabung dan raih kesuksesan bersama kami!
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Gambar Lingkungan Belajar -->
      <v-row>
        <v-col cols="12">
          <v-img src="/images/logo-bordered.png" height="300" alt="Lingkungan Belajar di LPK Aishin"></v-img>
        </v-col>
      </v-row>
  
      <!-- Narasi Promosi -->
      <v-row>
        <v-col cols="12">
          <v-typography class="subtitle-1 text-center">
            Di LPK Aishin, kami menawarkan lingkungan belajar yang modern dan inklusif, dengan fasilitas terbaru dan metode pengajaran yang efektif. Kami siap membantu Anda mencapai impian dan karir yang diinginkan.
          </v-typography>
        </v-col>
      </v-row>
  
      <!-- Tombol Navigasi ke Halaman Pendaftaran -->
      <v-row justify="center">
        <v-btn color="success" to="/reservation">Daftar Sekarang</v-btn>
      </v-row>
      <br>
    </v-container>
  </template>

  <script>
import auditrail  from "../../api/AuditrailApi";

export default {
  async mounted(){
    await auditrail.create({
      action: this.$route.path
    });
  },
}
  </script>
  
  <style>
    /* Custom styling as needed */
  </style>
  