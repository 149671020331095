<template>
  <v-container fluid>
    <h2 class="my-4 custom-text-9">CONTACT US</h2>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-form @submit.prevent="sendEmail">
          <v-text-field v-model="form.name" label="Name" outlined color="#12486B" required></v-text-field>
          <v-text-field v-model="form.email" label="Email" outlined color="#12486B" required type="email"></v-text-field>
          <v-textarea v-model="form.message" label="Message" outlined color="#12486B" required></v-textarea>
          <v-btn type="submit" large block color="#12486B" class="white--text">Send</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auditrail  from "../../../api/AuditrailApi";

export default {
  async mounted(){
    await auditrail.create({
      action: this.$route.path
    });
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      // ... other data properties ...
    };
  },
  methods: {
    async sendEmail() {
      try {
        // Here you would send the form data to your server-side script.
        // For demonstration, I'm using a placeholder URL.
        const response = await fetch('/api/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.form)
        });

        if (response.ok) {
          // Handle success
          alert('Email sent successfully!');
        } else {
          // Handle error
          alert('Failed to send email.');
        }
      } catch (error) {
        console.error('There was an error sending the email:', error);
      }
    }
  }
};
</script>

<style scoped>
.custom-text-9 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #12486B;
  text-align: center;
  padding-bottom: 10px;
}
</style>
