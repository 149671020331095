<template>
    <v-container fluid>
        <h2 class="text-center my-4 custom-h2">パンフレット</h2>
        <v-row align="center" justify="center">
            <v-col cols="12" md="3" class="pl-10">
                <!-- 画像用の領域 -->
                <v-img src="/images/coverbrocure.png" aspect-ratio="0.8" class="grey lighten-2"
                    :class="{ 'elevation-5': true }" style="border: 1px solid black;">
                    <!-- 必要に応じて、ここに画像の上にオーバーレイやテキストを追加できます -->
                </v-img>
            </v-col>

            <v-col cols="12" md="6">
                <v-card class="my-16" flat>
                    <v-card-text class="large-text px-8 py-12">
                        LPK Aishinのパンフレットをダウンロードして、LPK Aishinの詳細情報を入手してください。
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center py-3">
                        <v-btn color="primary" depressed @click="downloadPdf">
                            ダウンロード
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-container fluid>
            <!-- コンテンツの上部からのスペースのためにここにマージンクラスを追加しました -->
            <v-card flat class="mt-5">
                <v-row no-gutters justify="center">
                    <v-col cols="12" md="4" class="d-flex justify-center align-center">
                        <!-- ブランドのロゴと説明 -->
                        <v-row>
                            <v-col class="d-flex justify-center justify-md-start py-3">
                                <img src="/images/logo-bordered.png" alt="AISHIN Group Logo" height="150">
                                <!-- ここにロゴ画像を追加します -->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="d-flex justify-center justify-md-start py-3">
                                <!-- 認証やパートナーのロゴはここに追加できます -->
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="2">
                        <!-- ページセクション -->
                        <v-card flat>
                            <v-card-text class="large-text">
                                <div class="footer-heading">ページ</div>
                                <v-list dense>
                                    <v-list-item href="/ja/home">ホーム</v-list-item>
                                    <v-list-item href="/ja/member">メンバー</v-list-item>
                                    <v-list-item href="/ja/about">私たちについて</v-list-item>
                                    <v-list-item href="/ja/so">送信組織</v-list-item>
                                    <v-list-item href="/ja/activity">教育活動</v-list-item>
                                    <v-list-item href="/ja/contact">お問い合わせ</v-list-item>
                                    <v-list-item href="/ja/brocure">パンフレット</v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                        <!-- 住所セクション -->
                        <v-card flat>
                            <v-card-text class="large-text">
                                <div class="footer-heading">住所</div>
                                <div>Jl. Terusan I Gusti Ngurah Rai No.1, Pd. Kopi, Kec. Duren Sawit, East Jakarta City,
                                    Special Capital
                                    Region of Jakarta 13460</div>
                                <div>電話: (021) 8974540</div>
                                <div>Email: info@aishingroup.co.id</div>
                                <div>営業時間: 08:00 - 17:00</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-container>
</template>
  
<script>
export default {
    name: 'CustomLayout',

    methods: {
        downloadPdf() {
            const path = '/images/brosur.pdf'; // 実際のパンフレットの場所に合わせてください

            const link = document.createElement('a');
            link.href = path;
            link.setAttribute('download', 'brosur.pdf'); // ダウンロード時のファイル名を設定します
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>
  
<style>
.large-text {
    font-size: 18px;
    /* このクラスには2つのフォントサイズがありますが、最後に適用されるものが適用されます */
    font-family: 'Calibri', sans-serif;
    font-weight: 100;
    font-size: 22px;
    /* これは上記の18pxのフォントサイズを上書きします */
    text-align: justify;
    line-height: 1.5;
}

/* 必要に応じて追加のスタイル */
</style>
