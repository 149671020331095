<template>
    <div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

    components: {
    },
    computed: {
        ...mapGetters({
            currentLanguage: 'base/currentLanguage'
        }),
    },
    methods: {
    },
    mounted() {
        this.vuexFromStorage("base/currentLanguage")
        let currentLanguage = this.storageLoad("base/currentLanguage")
        if (!currentLanguage) {
            this.storageSave("base/currentLanguage", window.navigator.language);
            this.vuexFromStorage("base/currentLanguage")
            currentLanguage = window.navigator.language;

        }
        console.log(currentLanguage)
        if(currentLanguage.toLowerCase() == 'id' || currentLanguage.toLowerCase() == 'id-id')
        {
            this.$router.push('/id/home');
        }
        else if(currentLanguage.toLowerCase() == 'en-us' || currentLanguage.toLowerCase() == 'en-us-en-us')
        {
            this.$router.push('/en-us/home');
        }
        else if(currentLanguage.toLowerCase() == 'ja'|| currentLanguage.toLowerCase() == 'ja-ja')
        {
            this.$router.push('/ja/home');
        }
        else
        {
            this.$router.push('/en-us/home');
        }


        // this.toast(this.currentLanguage)

    }
}
</script>