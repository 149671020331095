<template>
  <v-app id="app">
    <v-navigation-drawer v-model="drawer" app temporary color="#222831" width="250px" class="custom-drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <span class="custom-text-drawer">LPK AISHIN INDONESIA</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item-group active-class="text--accent-4" v-for="item in listMenu" :key="item.url">
          <v-list-item @click="setLastPage(item.url)">
            <v-list-item-icon>
              <v-icon color="#FFFFFF">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="custom-text-sub-drawer">{{item.label}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="#222831" dense height="70px">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text text--darken-1"></v-app-bar-nav-icon>

      <v-toolbar-title id="toolbar-title">
        <span class="custom-text-inside white--text text--darken-1">LPK </span>
        <span class="custom-text-inside white--text text--darken-1">AISHIN GROUP</span>
      </v-toolbar-title>

      <!-- Tambahkan spacer agar LOGOUT berada di sisi kanan -->
      <v-spacer></v-spacer>

      <v-menu bottom offset-y>
  <template v-slot:activator="{ on, attrs }">
    <v-btn color="red" depressed rounded small text @click="logout()" v-bind="attrs" v-on="on" class="logout-button">
      <v-icon color="white" class="logout-icon">mdi-logout</v-icon>
      <span class="custom-text-out white--text">LOGOUT</span>
    </v-btn>
  </template>
</v-menu>

    </v-app-bar>


    <v-main>
      <router-view></router-view>
    </v-main>

    <ConfirmationDialog ref="confirmationDialog" />
    <DeleteDialog ref="deleteDialog" />
    <InformationDialog ref="informationDialog" />
    <LoadingDialog ref="loadingDialog" />
    <Snackbar ref="snackbar" />
  </v-app>
</template>

<script>
// import {useRoute} from "vue-router/composables";
import Swal from 'sweetalert2';

export default {
  name: "AppLayout",
  data() {
    return {
      drawer: null,
      identity: null,
      listMenu: [],
      listMenuClient: [
      {url: '/client/profile', icon: 'mdi-view-dashboard', label: 'Dashboard'},
{url: '/lpkmember', icon: 'mdi-account-details-outline', label: 'List of Graduates'},
      ],
      listMenuAdmin: [
      {url: '/client/profile', icon: 'mdi-view-dashboard', label: 'Dashboard'},
{url: '/lpkmember', icon: 'mdi-account-details-outline', label: 'List of Graduates'},
{url: '/clientlist', icon: 'mdi-account-details', label: 'Account List'},
{url: '/addmember', icon: 'mdi-account-multiple-outline', label: 'Add Graduates Data'},
{url: '/regclient', icon: 'mdi-account-multiple-plus-outline', label: 'Create Account'},
{url: '/auditrailreport', icon: 'mdi-chart-timeline-variant', label: 'Activity Report'},

      ],
    };
  },

  mounted(){    
  this.listMenu = this.listMenuClient;
  let identity = this.storageLoad("auth/identity");
  if(identity){
    if(identity.isAdmin == "administrator"){
      this.listMenu = this.listMenuAdmin;
    }
  }
},

  methods: {
    setLastPage(href) {
      this.storageSave("cache/lastPage", href.replace("/#", ""));
      this.$router.push(href)
    },
    async logout() {
      // Display confirmation with SweetAlert2
      let result = await Swal.fire({
        title: 'Are you sure?',
        text: "You will be logged out of the application.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Logout',
        cancelButtonText: 'Cancel'
      });

      // If confirmation is clicked, proceed to logout
      if (result.isConfirmed) {
        this.storageRemove("auth/identity");
        // Display success message
        await Swal.fire(
          'Success!',
          'You have been successfully logged out.',
          'success'
        );
        // Redirect to login page
        await this.$router.push("/login");
        // Reload the page to clear data
        this.$router.go(0);
      }
    },

  }
};
</script>
<style>
/* Menambahkan styling untuk title dan menu item */
.title {
  font-size: 20px; /* Ukuran font untuk judul */
}

.menu-item {
  font-size: 16px; /* Ukuran font untuk item menu */
}
.custom-text-out {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  margin-bottom: 12px;
}
.custom-text-drawer {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-align: justify;
}
.custom-text-sub-drawer {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: justify;
  margin-bottom: 12px;
}
.custom-text-inside {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  text-align: justify;
}
.logout-button .v-icon.logout-icon, .logout-button .custom-text-out {
  vertical-align: middle; /* Menjaga ikon dan teks sejajar di tengah */
}

.custom-text-out {
  margin-left: 8px; /* Menambahkan sedikit jarak antara ikon dan teks */
}
.custom-drawer {
  background-color: #222831; /* Warna latar */
}

.drawer-title, .drawer-item {
  padding: 10px; /* Padding untuk judul dan item */
}

.drawer-item .v-icon {
  color: #FFFFFF; /* Warna ikon */
}
</style>