<template>
  <v-snackbar v-model="visible" :timeout="timeout">
    <v-icon v-if="error">mdi-alert-circle-outline</v-icon>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn :color="error ? 'red' : 'blue'" text v-bind="attrs" @click="visible = false">CLOSE</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      visible: false,
      message: "",
      timeout: 3000,
      error: false
    };
  },
  methods: {
    show(message, timeout = 3000) {
      this.visible = true;
      this.message = message;
      this.timeout = timeout;
      this.error = false;
    },
    showError(error, timeout = 5000) {
      this.visible = true;
      this.message = "ERROR: " + this.getErrorMessage(error);
      this.timeout = timeout;
      this.error = true;
    }
  },
};
</script>
