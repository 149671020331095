<template>
  <v-dialog v-model="visible" close-delay="10000" persistent width="250">
    <v-card>
      <div class="px-10 py-5 text-center">
        <v-progress-circular color="deep-orange" indeterminate size="70"></v-progress-circular>
        <div class="text-caption mt-5">{{ message }}</div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LoadingDialog",
  data() {
    return {
      visible: false,
      message: "",
    };
  },

  methods: {
    show(message = "Memuat data...") {
      this.message = message
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },
  },
};
</script>