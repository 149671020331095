<template>
  <v-container fluid>
    <div class="my-4 custom-text-9-id" style="margin-top: 100px;">Hubungi Kami</div>
    <v-row justify="center">
      
      <v-col cols="12" md="8" lg="6">
        <v-card elevation="6" class="py-5 px-4 mx-3 text-center" color="#EEEEEE">
          <v-form @submit.prevent="submitForm">
            <!-- Name -->
            <v-text-field 
              v-model="nama" 
              label="Nama Lengkap" 
              outlined 
              class="mb-3"
              color="black"
              outlined-color="black"
              dense
              hide-details>
            </v-text-field>

            <!-- Age -->
            <v-text-field 
              v-model="umur" 
              label="Umur" 
              type="number" 
              outlined 
              class="mb-3"
              color="black"
              outlined-color="black"
              dense
              hide-details>
            </v-text-field>

            <!-- Email -->
            <v-text-field 
              v-model="email" 
              label="Alamat Email" 
              type="email" 
              outlined 
              class="mb-3"
              color="black"
              outlined-color="black"
              dense
              hide-details>
            </v-text-field>

            <!-- Phone Number -->
            <v-text-field 
              v-model="noTlp" 
              label="No. Telepon" 
              type="tel" 
              outlined 
              class="mb-3"
              color="black"
              outlined-color="black"
              dense
              hide-details>
            </v-text-field>

            <!-- Address -->
            <v-textarea 
              v-model="alamat" 
              label="Alamat Lengkap" 
              outlined 
              class="mb-4"
              color="black"
              outlined-color="black"
              dense
              hide-details>
            </v-textarea>

            <!-- Submit Button -->
            <v-btn 
              type="submit" 
              large 
              color="#F6C667" 
              class="white--text"
              dark>
              Kirim
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      nama: '',
      umur: null,
      email: '',
      noTlp: '',
      alamat: '',
    };
  },
  methods: {
    submitForm() {
      const whatsappLink = `https://wa.me/6282123983927?text=Halo, saya ingin bertanya soal LPK Aishin.%0A%0ANama: ${this.nama}%0AUmur: ${this.umur}%0AEmail: ${this.email}%0ANo. Tlp: ${this.noTlp}%0AAlamat: ${this.alamat}`;
      window.open(whatsappLink, '_blank');
    },
  },
};
</script>

<style>
.custom-text-9 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #900C27;
  text-align: center;
  padding-bottom: 10px;
}
</style>
