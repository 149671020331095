import http from "./http";

const auditrailApi = {
    endPoint: "auditrail",

    async getCache() {
        const response = await http.post(`${this.endPoint}/GetCache`);
        return response.data;
    },

    async update(resource) {
        const response = await http.post(`${this.endPoint}/update/${resource.id}`, resource);
        return response.data;
    },

    async delete(id) {
        await http.post(`${this.endPoint}/delete/${id}`);
    },
    async get(id) {
        const response = await http.post(`${this.endPoint}/get/${id}`);
        return response.data;
    },
    async getList() {
        const response = await http.get(`${this.endPoint}`);
        return response.data;
    },
    async create(resource) {
        console.log(resource)
        const response = await http.post(`${this.endPoint}`, resource);
        return response.data;
    },
};

export default auditrailApi;

