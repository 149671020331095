import store from "@src/store";
import mixin from "@src/mixin";
import axios from "axios";
import router from "@src/router";

const baseURL = () => {
    let baseURL = localStorage.getItem("auth/baseURL")
    // if(!baseURL) baseURL = "http://192.168.111.3"
    // if(!baseURL) 
        // baseURL = "https://localhost:44372/"
        baseURL = "https://lpkaishingroup.azurewebsites.net/"
    console.log(baseURL)
    return baseURL
};
const http = axios.create({
    // baseURL: process.env.VUE_APP_API_URL,
    /* dipindah ke sini, karena android gabisa baca env */
    baseURL: baseURL(),
    timeout: 300000

});
// http.defaults.headers.common['Content-Type'] = "application/json";
//  delete http.defaults.headers.common;

http.interceptors.request.use(request => {
    let identity = localStorage.getItem("auth/identity");
    
    if (!identity) {
        identity = mixin.methods.storageLoad("auth/identity");
        store.commit("auth/identity", identity);
    }

    if (identity)
        // request.headers.Authorization = `Bearer ${identity.accessToken}`;
        request.headers.Token = `${identity.token}`;
    // console.log("request :" + JSON.stringify(request));
    return request;
});

http.interceptors.response.use(
    response => {
        // console.log("response :" + JSON.stringify(response));
        return response;
    },
    async error => {
        if (error.response && error.response.status === 401) {

            if (error.config.url === 'api/auth/refresh-token') {
                await router.push("/login");
                return Promise.reject(error);
            }

            const refreshTokenRequest = {
                refreshToken: mixin.methods.storageLoad("refresh-token")
            };
            const newIdentity = (await http.post("api/auth/refresh-token", refreshTokenRequest)).data;
            store.commit("auth/identity", newIdentity);
            mixin.methods.storageSave("auth/identity", newIdentity);

            return http(error.config);
        }
        return Promise.reject(error);
    });

export default http;

