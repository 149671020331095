<template>
  <v-dialog v-model="visible" width="400" @keyup.esc="batal()">
    <v-card>
      <div class="pa-5 text-h6 grey--text text--darken-1">
        <v-icon>mdi-alert</v-icon>
        {{ title }}
      </div>

      <div class="px-5 pb-3 text-pre-wrap">
        <p>{{ message }}</p>
      </div>

      <div class="pa-3 text-right">
        <v-btn class="mr-2" text @click="batal()">
          <v-icon left>mdi-close</v-icon>
          Cancel
        </v-btn>
        <v-btn color="error" min-width="120px" @click="hapus()">
          <v-icon left>mdi-delete</v-icon>
          Delete
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  data() {
    return {
      visible: false,
      title: "",
      message: "",
      resolve: null,
      reject: null,
    };
  },
  methods: {
    show(
        message = "Anda yakin akan menghapus data ini?",
        title = "Hapus Data"
    ) {
      this.visible = true;
      this.title = title;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    hapus() {
      this.resolve(true);
      this.visible = false;
    },
    batal() {
      this.resolve(false);
      this.visible = false;
    },
  },
};
</script>
