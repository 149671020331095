import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import XLSX from "xlsx";
import mixin from "./mixin";
import CurrencyInput from "./components/CurrencyInput.vue";
import NumberInput from "./components/NumberInput.vue";
import DateInput from "./components/DateInput.vue";
import LoadingDialog from "./components/LoadingDialog.vue";
import ConfirmationDialog from "./components/ConfirmationDialog.vue";
import InformationDialog from "./components/InformationDialog.vue";
import DeleteDialog from "./components/DeleteDialog.vue";
import Snackbar from "./components/Snackbar.vue";
import YesNoColumn from "./components/YesNoColumn.vue";
import PurchaseOrderLookup from "./components/PurchaseOrderLookup";
import SalesOrderLookup from "./components/SalesOrderLookup";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';


Vue.config.productionTip = false;

//// component registration
Vue.component("CurrencyInput", CurrencyInput);
Vue.component("NumberInput", NumberInput);
Vue.component("DateInput", DateInput);
Vue.component("LoadingDialog", LoadingDialog);
Vue.component("ConfirmationDialog", ConfirmationDialog);
Vue.component("InformationDialog", InformationDialog);
Vue.component("DeleteDialog", DeleteDialog);
Vue.component("Snackbar", Snackbar);
Vue.component("YesNoColumn", YesNoColumn);
Vue.component("PurchaseOrderLookup", PurchaseOrderLookup);
Vue.component("SalesOrderLookup", SalesOrderLookup);

Vue.use(ToastPlugin);

//// prototype
Vue.prototype.$xlsx = XLSX;

//// mixin
Vue.mixin(mixin);

new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
}).$mount("#app");
