<template>
    <v-container fluid>
        <h2 class="my-4 custom-text-9-jp">組織体制</h2>
        <v-row justify="center">
            <v-col cols="12" md="9">
                <v-card flat>
                    <v-col cols="12" sm="12" style="text-align: center;">
                        <img src="/images/OS.jpg" style="max-width: 80%; height: auto;">
                    </v-col>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="my-4 custom-text-9-jp">送り出しスキーム（送出機関）</h2>
        <v-row justify="center">
            <v-col cols="12" md="9">

                <v-card flat>
                    <v-col cols="12" sm="12" style="text-align: center;">
                        <img src="/images/Home/jpn2.webp" style="max-width: 80%; height: auto;">
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <h2 class="my-4 custom-text-9-jp">送信結果</h2>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card class="chart-card" flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-select v-model="selectedYearForChart" :items="years" label="年" outlined
                                    @change="generateChartData"></v-select>
                            </v-col>
                        </v-row>
                        <div v-if="chartData.length > 0" class="chart-container">
                            <div v-for="(data, index) in chartData" :key="index" class="chart-row">
                                <div class="chart-label">{{ months[index] }}</div>
                                <div class="chart-bar">
                                    <div :style="{ width: (data.value / 200 * 100) + '%' }" class="bar">
                                        <span class="bar-value">{{ data.value }}</span>
                                    </div>
                                </div>
                                <div class="chart-value">{{ data.value }}</div>
                            </div>
                        </div>
                        <div v-else class="no-data">データなし。</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <h2 class="my-4 custom-text-9-jp">問題発生時の対応（送出機関）</h2>
        <v-row justify="center">
            <v-col cols="12" md="9">

                <v-card flat>
                    <v-col cols="12" sm="12" style="text-align: center;">
                        <img src="/images/Home/jpn3.webp" style="max-width: 80%; height: auto;">
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <!-- <h2 class="my-4 custom-text-4">Professional Skill</h2>
        <h2 class="my-4 custom-text-4">Technical Intern Trainee</h2> -->
    </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
    async mounted() {
        await auditrail.create({
            action: this.$route.path
        });
        this.generateChartData();
    },
    data: () => ({
        selectedYear: null,
        selectedMonth: null,
        personNumber: null,
        selectedYearForChart: 2024,
        years: [2021, 2022, 2023, 2024],
        months: [
            '1月', '2月', '3月', '4月', '5月', '6月',
            '7月', '8月', '9月', '10月', '11月', '12月'
        ],
        personData: {
            '2021-1月': 59, '2021-2月': 22, '2021-3月': 16, '2021-4月': 40, '2021-5月': 42, '2021-6月': 27,
            '2021-7月': 13, '2021-8月': 78, '2021-9月': 86, '2021-10月': 66, '2021-11月': 9, '2021-12月': 36,

            '2022-1月': 52, '2022-2月': 72, '2022-3月': 63, '2022-4月': 10, '2022-5月': 23, '2022-6月': 27,
            '2022-7月': 43, '2022-8月': 75, '2022-9月': 30, '2022-10月': 89, '2022-11月': 86, '2022-12月': 77,

            '2023-1月': 16, '2023-2月': 42, '2023-3月': 94, '2023-4月': 13, '2023-5月': 18, '2023-6月': 100,
            '2023-7月': 57, '2023-8月': 77, '2023-9月': 87, '2023-10月': 33, '2023-11月': 13, '2023-12月': 84,

            '2024-1月': 81, '2024-2月': 16, '2024-3月': 76, '2024-4月': 100, '2024-5月': 79, '2024-6月': 69,
            '2024-7月': 72, '2024-8月': 27, '2024-9月': 25, '2024-10月': 7, '2024-11月': 82, '2024-12月': 20,
        },
        chartData: []

    }),

    methods: {
        onYearChange() {
            this.selectedMonth = null;
            this.personNumber = null;
            this.chartData = [];
        },
        onMonthChange() {
            const key = `${this.selectedYearForPerson}-${this.selectedMonth}`;
            this.personNumber = this.personData[key] || 'Data tidak tersedia';
        },
        generateChartData() {
            this.chartData = [];
            if (!this.selectedYearForChart) return;

            this.months.forEach((month) => {
                const value = this.personData[`${this.selectedYearForChart}-${month}`] || 0;
                this.chartData.push({
                    month: month,
                    value: value,
                    color: this.getRandomColor()
                });
            });
        },
        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }
    }
};
</script>

<style>
.chart-card {
    overflow-x: auto;
    padding: 16px;
}

.chart-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.chart-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.chart-label {
    width: 80px;
    text-align: left;
    margin-right: 10px;
}

.chart-bar {
    flex-grow: 1;
    background-color: #e0e0e0;
    position: relative;
}

.bar {
    height: 100%;
    background-color: #12486B;
    display: flex;
    align-items: center;
}

.bar-value {
    color: white;
    margin-left: 5px;
}

.chart-value {
    width: 50px;
    text-align: left;
    margin-left: 10px;
}

.no-data {
    text-align: center;
    color: red;
}

.person-info {
    font-weight: bold;
}

.custom-text {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
}

.company-profile-table th,
.company-profile-table td {
    border: 1px solid black;
}

.company-profile-table thead .black-header {
    background-color: #000;
    color: #fff;
}


.company-profile-table {
    border-collapse: collapse;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}
</style>
