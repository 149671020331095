<template>
    <v-container fluid>
        <h2 class="text-center my-4 custom-h2">BROSUR</h2>
        <v-row align="center" justify="center">
            <v-col cols="12" md="3" class="pl-10">
                <!-- Untuk Gambar -->
                <v-img src="/images/coverbrocure.png" aspect-ratio="0.8" class="grey lighten-2"
                    :class="{ 'elevation-5': true }" style="border: 1px solid black;">
                    <!-- Anda bisa menambahkan overlay atau teks di atas gambar di sini jika perlu -->
                </v-img>
            </v-col>

            <v-col cols="12" md="6">
                <v-card class="my-16" flat>
                    <v-card-text class="large-text px-8 py-12">
                        Download Brosur LPK Aishin untuk mendapatkan informasi lebih detail dari LPK Aishin.
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center py-3">
                        <v-btn color="primary" depressed @click="downloadPdf">
                            Download
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-container fluid>
            <!-- Margin top class added here for spacing from the content above -->
            <v-card flat class="mt-5">
                <v-row no-gutters justify="center">
                    <v-col cols="12" md="4" class="d-flex justify-center align-center">
                        <!-- Brand Logo and Description -->
                        <v-row>
                            <v-col class="d-flex justify-center justify-md-start py-3">
                                <img src="/images/logo-bordered.png" alt="AISHIN Group Logo" height="150">
                                <!-- Add your logo image here -->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="d-flex justify-center justify-md-start py-3">
                                <!-- Logos for certifications or partners can go here -->
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="2">
                        <!-- Halaman Section -->
                        <v-card flat>
                            <v-card-text class="large-text">
                                <div class="footer-heading">Halaman</div>
                                <v-list dense>
                                    <v-list-item href="/id/home">Home</v-list-item>
                                    <v-list-item href="/id/member">Member Kami</v-list-item>
                                    <v-list-item href="/id/about">Tentang Kami</v-list-item>
                                    <v-list-item href="/id/so">Sending Organization</v-list-item>
                                    <v-list-item href="/id/activity">Aktivitas Pendidikan</v-list-item>
                                    <v-list-item href="/id/contact">Kontak</v-list-item>
                                    <v-list-item href="/id/faq">FAQ</v-list-item>
                                    <v-list-item href="/id/career">Karir</v-list-item>
                                    <v-list-item href="/id/brocure">Brocure</v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                        <!-- Alamat Section -->
                        <v-card flat>
                            <v-card-text class="large-text">
                                <div class="footer-heading">Alamat</div>
                                <div>Jl. Terusan I Gusti Ngurah Rai No.1, Pd. Kopi, Kec. Duren Sawit, East Jakarta City,
                                    Special Capital
                                    Region of Jakarta 13460</div>
                                <div>Tel: (021) 8974540</div>
                                <div>Email: info@aishingroup.co.id</div>
                                <div>Jam Kerja: 08:00 - 17:00</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-container>
</template>
  
<script>
export default {
    name: 'CustomLayout',

    methods: {
        downloadPdf() {
            const path = '/images/brosur.pdf'; // Sesuaikan dengan lokasi sebenarnya dari brosur pdf Anda

            const link = document.createElement('a');
            link.href = path;
            link.setAttribute('download', 'brosur.pdf'); // Atur nama file saat di-download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>
  
<style>
.large-text {
    font-size: 18px;
    /* Anda memiliki dua font-size di class ini, yang terakhir yang akan diaplikasikan */
    font-family: 'Calibri', sans-serif;
    font-weight: 100;
    font-size: 22px;
    /* Ini akan menimpa font-size 18px di atas */
    text-align: justify;
    line-height: 1.5;
}

/* Style tambahan jika perlu */
</style>