<template>
  <v-dialog v-model="visible" width="800" @keyup.esc="cancel()">
    <v-card>
      <div class="pa-5 text-h6">
        <v-icon color="orange">mdi-table-search</v-icon>
        Select Sales Order
      </div>

      <div class="pa-5">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="pb-3"
            dense
            hide-details
            label="Search"
            outlined
            single-line
        ></v-text-field>

        <v-data-table
            :headers="headers"
            :items="datasource"
            :items-per-page="5"
            :search="search"
            style="cursor: pointer"
            @click:row="clickRow($event)"
        >
          <template v-slot:item.documentDate="{ item }">{{ formatDate(item.documentDate) }}</template>
          <template v-slot:item.total="{ item }">{{ formatNumber(item.total) }}</template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "SalesOrderLookup",
  data() {
    return {
      visible: false,
      search: "",
      headers: [
        {text: "Document No.", value: "documentNumber"},
        {text: "Document Date", value: "documentDate"},
        {text: "Status", value: "status"},
        {text: "Customer Code", value: "customerCode"},
        {text: "Customer Name", value: "customerName"},
        {text: "Currency", value: "currencyCode"},
        {text: "Total", value: "total"},
      ],
      datasource: [],
      selected: null,
      resolve: null,
      reject: null,
    };
  },

  methods: {
    async show(datasource) {
      this.visible = true;
      
      this.datasource = datasource;
      
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    clickRow(event) {
      this.selected = event;
      this.select();
    },

    select() {
      this.resolve(this.selected);
      this.visible = false;
    },

    cancel() {
      this.resolve(null);
      this.visible = false;
    },
  },
};
</script>