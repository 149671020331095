<template>
  <v-main>
    <v-container>
      <v-card-title class="custom-text-4">List Of Graduates</v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search by name..."
        single-line
        hide-details
      ></v-text-field>
      <!-- Tabel Daftar Member -->
      <v-card>
        <v-data-table
          :headers="headers"
          :items="members"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:item.birthDay="{ item }">
            {{ formatDate(item.birthDay) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="viewProfile(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog v-model="dialog" max-width="800px">
        <v-card>
          <v-card-title class="custom-text-4">Profile Detail</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{{ selectedMember.name }}</td>
                  </tr>
                  <tr>
                    <td>Birth Place</td>
                    <td>{{ selectedMember.birthPlace }}</td>
                  </tr>
                  <tr>
                    <td>Born Date</td>
                    <td>{{ selectedMember.birthDay }}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{{ selectedMember.gender }}</td>
                  </tr>
                  <tr>
                    <td>Religion</td>
                    <td>{{ selectedMember.religion }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{{ selectedMember.phone }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ selectedMember.email }}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{{ selectedMember.address }}</td>
                  </tr>
                  <tr>
                    <td>Education</td>
                    <td>{{ selectedMember.education }}</td>
                  </tr>
                  <tr>
                    <td>Company Name in Japan</td>
                    <td>{{ selectedMember.companyNameJapan }}</td>
                  </tr>
                  <tr>
                    <td>Company Address in Japan</td>
                    <td>{{ selectedMember.companyAddressJapan }}</td>
                  </tr>
                  <tr>
                    <td>Date to Japan</td>
                    <td>{{ selectedMember.dateToJapan }}</td>
                  </tr>
                  <tr>
                    <td>JLPT Certificate</td>
                    <td>{{ selectedMember.jlptCertificate }}</td>
                  </tr>
                  <tr>
                    <td>Skills</td>
                    <td>{{ selectedMember.skills }}</td>
                  </tr>
                  <tr>
                    <td>LPK Origin</td>
                    <td>{{ selectedMember.LPKOrigin }}</td>
                  </tr>
                  <tr>
                    <td>Date Joined LPK</td>
                    <td>{{ formatDate(selectedMember.dateJoinLPK) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import employeeApi from "../../api/EmployeeApi";
import auditrail from "../../api/AuditrailApi";

export default {
  data() {
    return {
      members: [],
      dialog: false,
      selectedMember: {},
      search: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Born Date', value: 'birthDay' },
        { text: 'Gender', value: 'gender' },
        { text: 'Religion', value: 'religion' },
        { text: 'JLPT Certificate', value: 'jlptCertificate' },
        { text: 'View Detail', value: 'action', sortable: false },
      ],
    };
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    },
    async loadData() {
      try {
        const response = await employeeApi.getList();
        console.log("Complete data received:", response); // Cek data lengkap yang diterima
        this.members = response;
      } catch (error) {
        console.error("Error loading data:", error);
      }
    }
    ,
    viewProfile(member) {
      this.selectedMember = { ...member };
      this.selectedMember.birthDay = this.formatDate(member.birthDay);
      this.selectedMember.dateToJapan = this.formatDate(member.dateToJapan);
      this.selectedMember.dateJoinLPK = this.formatDate(member.dateJoinLPK);
      console.log("Selected Member:", this.selectedMember);
      this.dialog = true;
    },
  },
  async mounted() {
    this.loadData();
    await auditrail.create({
      action: this.$route.path
    });
  }
};
</script>

<style scoped>
.v-footer {
  color: white;
}

.v-card,
.v-dialog {
  margin-top: 1cm;
  margin-bottom: 1cm;
}

.v-card-title {
  font-size: 24px;
  font-weight: bold;
}

.v-card-text div {
  padding-bottom: 4px;
  /* Menambahkan padding di bawah setiap baris */
}
</style>
