<template>
  <v-app>
    <v-app-bar app style="background-color: #12486B; color: white;">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <!-- Regular Navbar for larger screens -->
        <v-toolbar-title>
          <a href="/" class="logo-link">
            <v-img src="../../../../public/images/logo-bordered.png" height="65px" contain class="logo"></v-img>
          </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn class="custom-text" text href="/en-us/member">About</v-btn>
        <v-btn class="custom-text" text href="/en-us/about">Company Profile</v-btn>
        <v-btn class="custom-text" text href="/en-us/so">Sending Organization</v-btn>
        <v-btn class="custom-text" text href="/en-us/activity">Educational Activities</v-btn>
        <v-btn class="custom-text" text href="/en-us/contact">Contact</v-btn>
        <v-btn class="custom-text" text @click="downloadPdf">Download Brochure</v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="custom-text" dark v-bind="attrs" v-on="on">
              Language
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <template>
            <v-list text-center>
              <v-list-item @click="goPage('/id/home', 'id')">
                <v-list-item-title class="custom-text-lang">Indonesia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goPage('/ja/home', 'ja')">
                <v-list-item-title class="custom-text-lang">日本語</v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-menu>
        <v-btn elevation="0" color="#088395" class="custom-text" @click="goPage('/login')">Login</v-btn>
      </template>

      <template v-else>
        <!-- Burger Menu for smaller screens -->
        <v-app-bar-nav-icon @click="drawer = !drawer" color="white"></v-app-bar-nav-icon>
        <v-toolbar-title>
          LPK AISHIN INDONESIA
        </v-toolbar-title>
      </template>
    </v-app-bar>
    <!-- Sidebar for smaller screens -->
    <v-navigation-drawer v-model="drawer" app temporary :clipped="$vuetify.breakpoint.lgAndUp"
      style="background-color: #12486B; color: white;">
      <v-list>
        <v-list-item link to="/">
          <v-list-item-title class="custom-text">Home</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/en-us/member">
          <v-list-item-title class="custom-text">About</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/en-us/about">
          <v-list-item-title class="custom-text">Company Profile</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/en-us/so">
          <v-list-item-title class="custom-text">Sending Organization</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/en-us/activity">
          <v-list-item-title class="custom-text">Educational Activities</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/en-us/contact">
          <v-list-item-title class="custom-text">Contact</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="downloadPdf">
          <v-list-item-title class="custom-text">Download Brochure</v-list-item-title>
        </v-list-item>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title class="custom-text">Language
                <v-icon right>mdi-chevron-down</v-icon>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template>
            <v-list text-center>
              <v-list-item @click="goPage('/id/home', 'id')">
                <v-list-item-title class="custom-text-lang">Indonesia</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goPage('/ja/home', 'ja')">
                <v-list-item-title class="custom-text-lang">日本語</v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </v-menu>
      </v-list>
      <v-list-item link @click="goPage('/login')">
          <v-list-item-title class="custom-text">Login</v-list-item-title>
        </v-list-item>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>

    <div class="floating-icon">
      <v-btn color="green" dark fab @click="openWhatsApp2">
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </div>

    <v-footer color="white" padless>
      <v-container fluid>
        <v-card flat>
          <v-row no-gutters justify="center">
            <v-col cols="12" md="2" class="d-flex justify-center align-center">
              <v-row>
                <v-col cols="auto" class="d-flex justify-center justify-md-start py-3">
                  <img src="/images/logo-bordered.png" alt="AISHIN Group Logo" height="100">
                  <!-- Logo image -->
                </v-col>
                <v-col cols="auto" class="d-flex justify-center justify-md-start py-3 custom-text-5">
                  <!-- Informasi LPK Aishin -->
                  <div>
                    <p>Registered Training Organization</p>
                    <p>&copy; 2023 LPK AISHIN INDONESIA</p>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="2">
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <div class="custom-text-3">Pages</div>
                  <v-list dense style="text-align: right;">
                    <v-list-item href="/en-us/home">Home</v-list-item>
                    <v-list-item href="/en-us/member">About</v-list-item>
                    <v-list-item href="/en-us/about">Company Profile</v-list-item>
                    <v-list-item href="/en-us/so">Sending Organization</v-list-item>
                    <v-list-item href="/en-us/activity">Educational Activities</v-list-item>
                    <v-list-item href="/en-us/contact">Contact</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <!-- Address Section -->
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <div class="custom-text-3" style="margin-bottom: 10px;">Indonesia Address</div>

                  <div>Jl. Terusan I Gusti Ngurah Rai No.1, Pd. Kopi, Kec. Duren Sawit, East Jakarta City, Special
                    Capital
                    Region of Jakarta 13460</div>
                  <div>Phone Number : (021) 8974540</div>
                  <div>Email: info@aishingroup.co.id</div>
                  <div>Working Hours: 08:00 - 17:00</div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.345766535414!2d106.93774287458953!3d-6.218052560901538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698d66ea2c3fa7%3A0x3736392fd4a7abfb!2sLPK%20Aishin%20Indonesia!5e0!3m2!1sid!2sid!4v1708942578167!5m2!1sid!2sid"
                    width="300" height="150" style="border:0; display: block; margin: auto; margin-top: 20px;"
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                  </iframe>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <!-- Address Section -->
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <div class="custom-text-3" style="margin-bottom: 10px;">Japan Address</div>
                  <div>〒166-0004 Tokyo, Shinjuku-ku, Minami-Dai 1-27-22 302</div>
                  <div>Phone Number : (+81) 80 8913 6791</div>
                  <div>lpk.office.tokyo.jp@aishin.group</div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.7768021180573!2d139.63300453172545!3d35.70710975786635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f277ce9e3fa3%3A0x69a73a0e752fabd8!2s1-ch%C5%8Dme-22%20Asagayakita%2C%20Suginami%20City%2C%20Tokyo%20166-0001%2C%20Jepang!5e0!3m2!1sid!2sid!4v1708943365113!5m2!1sid!2sid"
                    width="300" height="150" style="border:0; display: block; margin: auto; margin-top: 85px;"
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                  </iframe>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      fab: false,
    };
  },
  methods: {
    openWhatsApp() {
      const whatsappLink = `https://wa.me/6282123983927?text=Halo, I want to ask something about LPK Aishin.`;
      window.open(whatsappLink, '_blank');
    },
    toggleFab() {
      this.fab = !this.fab;
      console.log('Fab state:', this.fab);
    },
    openWhatsApp2() {
      console.log('openWhatsApp2 clicked');
      const whatsappLink = `https://wa.me/6282123983927?text=Halo, I want to ask something about LPK Aishin.`;
      window.open(whatsappLink, '_blank');
    },
    downloadPdf() {
      const link = document.createElement('a');
      link.href = '/images/brosur.pdf';
      link.download = 'brosur.pdf';
      link.click();
    },
  }
};
</script>

<style scoped>
.custom-text {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: white;
  text-align: justify;
}

.custom-text-2 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 9px;
  color: white;
  text-align: justify;
}

.custom-text-lang {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.floating-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>
