<template>
  <v-container fluid>
    <v-layout row justify-center mt-5>
      <v-flex xs12 sm12 md9>
        <v-carousel height="500" cycle :interval="3000" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(item, i) in sliders" :key="i" :src="item.src" reverse-transition="fade-transition"
            transition="fade-transition" align-start>
            <v-container fluid>
              <v-row class="fill-height pa-5">
                <v-col cols="12" md="6" class="d-flex flex-column justify-center align-start">
                  <transition name="fade" mode="out-in">
                    <div key="item.title" class="text-container" v-if="i === 1 || i === 2">
                      <h3 class="mb-2 custom-text-7">{{ item.title }}</h3>
                      <p class="custom-text-8">{{ item.description }}</p>
                      <v-btn text v-if="i === 1" class="mt-4 custom-text-8" @click="goPage('/login')">Login
                        &gt;&gt;&gt;</v-btn>
                      <v-btn text v-if="i === 2" class="mt-4 custom-text-8" @click="goPage('/reservation')">Register Now
                        &gt;&gt;&gt;</v-btn>
                    </div>
                  </transition>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <!-- Gambar atau konten lain untuk sisi kanan -->
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
    <div style="height: 2cm;"></div>
    <v-container fluid>
      <v-row align="center" justify="center" class="my-4">
        <!-- Kolom untuk Logo -->
        <v-col cols="12" sm="3" class="text-center">
          <img src="/images/logo-bordered.png" style="max-width: 100%; height: auto;">
        </v-col>

        <!-- Kolom untuk Judul/Sub Judul dan Teks Tambahan -->
        <v-col cols="12" sm="6" class="text-center">
          <div>
            <h1 class="custom-text-7 black--text">LPK AISHIN INDONESIA</h1>
            <h2 class="custom-text-3 black--text">SENDING ORGANIZATION FROM INDONESIA</h2>
            <p class="custom-text-3 black--text">JOIN STEPS, BRIGHT FUTURE INDONESIA AND JAPAN BUILDING DREAMS</p>
          </div>
        </v-col>
      </v-row>

      <!-- Baris untuk Motto -->
      <v-row justify="center" class="my-4">
      <v-col cols="12" md="8">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="3" v-for="(motto, index) in mottos" :key="index">
            <v-card :color="motto.color" class="d-flex flex-column justify-center align-center pa-5 motto-card">
              <v-icon size="48" color="#ffffff">{{ motto.icon }}</v-icon>
              <div class="motto-text">{{ motto.text }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </v-container>

    <div class="my-4 custom-text-9" style="margin-top: 100px;">WHAT IS LPK AISHIN INDONESIA?</div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <v-row align="center">

            <!-- Kolom untuk foto -->
            <v-col cols="12" md="4" style="text-align: center;">
              <img src="/images/teachers/berman.webp" style="max-width: 60%; height: auto;">
            </v-col>

            <!-- Kolom untuk teks -->
            <v-col cols="12" md="8">

              <v-card-text class="custom-text-2">
                <p>As an institution officially recognized by the Indonesian Ministry of Manpower, LPK AISHIN INDONESIA
                  is
                  committed to implementing both Japanese and Indonesian legal systems in the management of labor supply
                  businesses, adhering strictly to legal regulations.</p>
                <p>For more than a decade, our expertise in labor supply business in Indonesia has been the top choice
                  for
                  Japanese companies expanding their businesses in Indonesia, entrusting us with the procurement of
                  contract
                  workers and internship participants.</p>
                <p>We collaborate closely with various other supply agencies and Japanese language education
                  institutions in
                  Indonesia, as well as working with local governments, to ensure the availability of quality human
                  resources that meet the specific needs of companies quickly and accurately. Every day, we strive
                  diligently to continue strengthening trust as a safe and reliable labor supply organization for the
                  business world.</p>
              </v-card-text>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-layout row wrap>
      <v-flex xs12>
        <div class="custom-text-9" style="margin-bottom: 60px;">Maintain Legitimacy</div>
      </v-flex>
      <v-card flat>
        <v-layout row wrap>
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 sm3>
              <v-card flat class="d-flex flex-column align-center justify-center">
                <v-img src="/images/Home/Home-1.webp" style="max-width: 80%; max-height: 250px;"
                  class="grey lighten-2"></v-img>
                <v-card-text class="custom-text">Labor Office Permit Inspection</v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 sm3>
              <v-card flat class="d-flex flex-column align-center justify-center">
                <v-img src="/images/Home/Home-2.webp" style="max-width: 80%; max-height: 200px;"
                  class="grey lighten-2"></v-img>
                <v-card-text class="custom-text">Listening Activities by Students</v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card>
    </v-layout>

    <div style="height: 2cm;"></div>
    <div class="my-4 custom-text-9" style="margin-top: 100px;">What They Say About LPK Aishin?</div>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="mx-auto" flat>
          <v-carousel v-model="carousel" hide-delimiters show-arrows cycle height="400px" class="elevation-2">
            <v-carousel-item v-for="(feedback, index) in feedbacks" :key="index">
              <v-row no-gutters align="center" class="fill-height">
                <v-col cols="4" class="d-flex justify-center align-center">
                  <v-avatar size="120" class="elevation-6">
                    <!-- Contoh menggunakan gambar dari placeholder.com sebagai alternatif -->
                    <v-img :src="feedback.image" class="br-20"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="8" class="d-flex align-center">
                  <div class="feedback-text">{{ feedback.text }}</div>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
  async mounted() {
    await auditrail.create({
      action: this.$route.path
    });
  },
  data() {
    return {
      mottos: [
        { text: "ENTHUSIASM", icon: "mdi-fire" },
        { text: "PATIENCE", icon: "mdi-timer-sand" },
        { text: "HUMILITY", icon: "mdi-hands-pray" },
        { text: "AMBITION", icon: "mdi-flag-checkered" },
        { text: "TRUST", icon: "mdi-handshake" },
        { text: "INFINITE", icon: "mdi-infinity" },
        { text: "CHALLENGE", icon: "mdi-trophy" },
      ],
      sliders: [
        {
          src: '/images/Stock/2.webp',
          title: '',
          description: ''
        },
        {
          src: '/images/Stock/1.1.webp',
          title: 'Japan-Indonesia',
          description: 'Unlock a world of promising Indonesian workforce prospects for your enterprise. Begin your journey—Login to connect.',
        },
        {
          src: '/images/Stock/2.2.webp',
          title: 'Japan Awaits: Launch Your Global Career',
          description: 'Embrace the opportunity to soar with a career in Japan. Join LPK Aishin, where your journey to international success begins.',
        },
      ],
      carousel: 0,
      feedbacks: [
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHIN has been a cornerstone of my career development. Their guidance is unparalleled.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'The opportunities LPK AISHIN provided have transformed my professional life. A true partner in growth.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Amazing support from LPK AISHIN! Their commitment to excellence is evident in all they do.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'With LPK AISHIN, I found more than a job; I found a career path in Japan. Forever thankful.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Professional, supportive, and dedicated. LPK AISHIN stands out as a beacon for those looking to work in Japan.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'The team at LPK AISHIN genuinely cares about your success. Their support has been pivotal for me.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Working with LPK AISHIN has been a game-changer. Their expertise in bridging cultures is impressive.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHIN is not just an organization; it’s a gateway to fulfilling your dreams in Japan.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'The professionalism and dedication of LPK AISHIN are unmatched. Highly recommended for anyone looking to work in Japan.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Finding LPK AISHIN was a turning point in my career. Their guidance made my transition to working in Japan seamless.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'From the very start, LPK AISHIN has been an invaluable partner. Their expertise and support have been key to my success in Japan.'
        }
      ],
    };
  },
  methods: {
    goPage(path) {
      this.$router.push(path); // Memastikan kamu sudah mengonfigurasi vue-router
    },
    next() {
      const next = this.carousel + 1;
      this.carousel = next % this.feedbacks.length;
    },
    prev() {
      const prev = this.carousel - 1;
      this.carousel = prev >= 0 ? prev : this.feedbacks.length - 1;
    },
  },
};
</script>

<style scoped>
.feedback-text {
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
}

.v-carousel {
  border: 2px solid #E0E0E0;
  border-radius: 20px;
}

.text-container {
  padding-top: 50px;
  padding-left: 50px;
}

.v-btn {
  text-decoration: underline;
  padding-left: 0;
}

.custom-text-7 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 42px;
  color: white;
  text-align: justify;
}

.custom-text-8 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  text-align: justify;
}

.custom-text-9 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #12486B;
  text-align: center;
  padding-bottom: 10px;
}

.carousel-container {
  max-width: 100%;
  padding-top: 100px;
}

.v-carousel {
  margin: 0 auto;
  padding-top: 10px;
}

.motto-card {
  height: 200px;
  /* Tentukan tinggi tetap untuk kotak */
  width: 100%;
  /* Agar lebar mengisi kolom */
  margin-bottom: 16px;
  /* Jarak antar kotak */
  background-color: #3F72AF;
  color: white; /* Agar teksnya terlihat di atas background warna biru */
}

.motto-year {
  font-size: 24px;
  /* Ukuran teks untuk tahun */
  color: black;
  /* Warna teks untuk tahun */
  margin-bottom: 8px;
  /* Jarak antara tahun dan teks motto */
}

.motto-text {
  font-size: 18px;
  color: white; /* Agar teksnya terlihat di atas background warna biru */
  text-transform: uppercase;
  padding: 0 10px;
}

/* Menambahkan media query untuk responsivitas */
@media (max-width: 600px) {
  .motto-card {
    height: 150px;
    /* Kotak lebih kecil untuk layar yang lebih kecil */
  }
}

.motto-card {
  transition: all 0.3s ease;
}

.motto-card:hover {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
  transform: scale(1.05);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active di <2.1.8 */
  {
  opacity: 0;
}
</style>
