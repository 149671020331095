const FilterModule = {
    namespaced: true,
    
    state: {
        stockTaking: {},
    },

    getters: {
        stockTaking: (state) => state.stockTaking,
    },

    mutations: {
        stockTaking: (state, payload) => state.stockTaking = payload,
    },
};

export default FilterModule;