<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text>
        <h2 class="my-8 custom-text-9-id">PROFIL PERUSAHAAN</h2>
        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-simple-table class="company-profile-table centered-table custom-text-5">
              <template v-slot:default>
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nama Perusahaan</td>
                    <td>LPK AISHIN INDONESIA</td>
                  </tr>
                  <tr>
                    <td>Tanggal Pendirian</td>
                    <td>15 Juni 2015</td>
                  </tr>
                  <tr>
                    <td>Nomor Perusahaan</td>
                    <td>73.403.175.0-071.000</td>
                  </tr>
                  <tr>
                    <td>Organisasi Pengirim</td>
                    <td>12 Mei 2023</td>
                  </tr>
                </tbody>

              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card flat>
          <v-card-text>
            <h2 class="text-center my-4 custom-text-6">BISNIS DI INDONESIA</h2>
            <v-simple-table class="company-profile-table centered-table custom-text-5">
              <template v-slot:default>
                <thead>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Bisnis Outsourcing</td>
                  </tr>
                  <tr>
                    <td>Bisnis Pengiriman Tenaga Kerja</td>
                  </tr>
                  <tr>
                    <td>Agen Visa Kerja</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>

      </v-col>
      <v-col cols="12" md="4">
        <v-card flat>
          <v-card-text>
            <h2 class="text-center my-4 custom-text">BISNIS DI JEPANG</h2>
            <v-simple-table class="company-profile-table centered-table custom-text-6">
              <template v-slot:default>
                <thead>
                  <tr>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Pusat Pelatihan Bahasa Jepang</td>
                  </tr>
                  <tr>
                    <td>Organisasi Pengirim Pekerja Magang Teknis Asing</td>
                  </tr>
                  <tr>
                    <td>Bisnis Rekrutmen Pekerja Terampil Spesifik</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h2 class="custom-text-9-id">LISENSI & PERIZINAN</h2>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card flat>
          <v-card-text>
            <v-simple-table class="company-profile-table centered-table custom-text-5">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Pendaftaran Perusahaan</td>
                    <td>No.18 Tanggal 15 Juni 2015 ABDUL RAJAB RAHMAN, SH., M.Kn.</td>
                  </tr>
                  <tr>
                    <td>Izin Perusahaan</td>
                    <td>AHU-2447532.AH.01.01.TAHUN 2015</td>
                  </tr>
                  <tr>
                    <td>Izin Pusat Pelatihan</td>
                    <td>No.1/G.1/31.75.07.1007.01..K.2/3-.851.332/e/2021</td>
                  </tr>
                  <tr>
                    <td>Izin Keberangkatan Pekerja</td>
                    <td>No.503/2383/7IOLPKS/V/DPMPTSP/2021</td>
                  </tr>
                  <tr>
                    <td>Izin Pengiriman Peserta Pelatihan</td>
                    <td>No.563/KEP.3658/LATTAS/2021</td>
                  </tr>
                  <tr>
                    <td>Izin Operasi Pengirim</td>
                    <td>2/1402/HK.03.01/V/2023</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <h2 class="my-8 custom-text-9-id">MITRA PERUSAHAAN</h2>
    <v-container>
      <v-card flat>
        <v-card-text class="text-center custom-text-5">
          <v-row justify="center">
            <v-col cols="12" md="4" lg="4" v-for="partner in partners" :key="partner.name">
              <v-card :color="partner.color"
                class="d-flex flex-column justify-center align-center pa-5 motto-card hover-scale" flat>
                <v-icon size="64" class="mb-3">mdi-briefcase</v-icon>
                <h3 class="partner-title custom-text-5">{{ partner.name }}</h3>
                <p class="partner-description custom-text-5">{{ partner.description }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

  </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
  async mounted() {
    await auditrail.create({
      action: this.$route.path
    });
  },
  data() {
    return {
      partners: [
        {
          name: 'Kemitraan Internasional LPK Kota Bekasi',
          description: 'Jawa Barat'
        },
        {
          name: 'LPK Bogor',
          description: 'Kota Bogor, Provinsi Jawa Barat'
        },
        {
          name: 'LPK Semarang',
          description: 'Jawa Tengah'
        },
        {
          name: 'LPK Hikari Sumatera Utara',
          description: 'Kota Medan, Provinsi Sumatera Utara'
        },
        {
          name: 'LPK Siantar',
          description: 'Sumatera Utara'
        },
        {
          name: 'Kota Salatiga',
          description: 'Provinsi Jawa Tengah'
        },
        {
          name: 'Kabupaten Toba Samosir',
          description: 'Sumatera Utara'
        },
      ],

    };
  }
};
</script>

<style>
.v-icon {
  margin-bottom: 10px;
}

.company-profile-table tbody tr:hover {
  background-color: transparent !important;
}

.partner-description,
.partner-title {
  word-wrap: break-word;
  /* Memastikan teks pindah baris dan tidak overflow */
  padding: 0 8px;
  /* Menambahkan padding agar teks tidak menyentuh tepi kolom */
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}


</style>
