const AuthModule = {
    namespaced: true,

    state: {
        identity: null,
        baseURL: null,
    },

    getters: {
        identity: (state) => state.identity,
        baseURL: (state) => state.baseURL
    },

    mutations: {
        identity: (state, payload) => state.identity = payload,
        baseURL: (state, payload) => state.baseURL = payload
    },
};

export default AuthModule;