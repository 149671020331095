<template>
    <v-container fluid>
        <div class="my-4 custom-text-9-jp">会社の哲学</div>
        <v-row>
            <v-col cols="12" sm="4" class="text-right">
                <v-avatar size="250px" style="padding-right: 20px; border-radius: 12px;">
                    <img src="/images/teachers/berman.webp" alt="President-Director Berman Nainggolan"
                        style="border-radius: 12px;">
                </v-avatar>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card flat>
                    <v-card-text class="custom-text-2 text-justify">
                        <p> "インドネシアの若者には夢があります。最初は働くことです... インドネシアでは、25歳未満の失業率がほぼ20％に達し、多くの若い世代が働きたいと考えています。</p>

                        <p> 二番目は私の夢を実現することです... 自然に恵まれた日本を旅し、神聖なアニメの場所を訪れ、秋葉原で商品を購入する。</p>

                        <p> 日本のアニメ文化は、インドネシアの若者に浸透しています。そして最後に、"未来を築く"... 日本に行く若者たちは、日本での仕事の経験に基づいて、日本企業での就職や起業を夢見ています。</p>

                        <p> LPK AISHIN INDONESIAの経営理念は、社会が自分の夢を実現するのを助けることです。私たちの使命は、できるだけ多くの若い世代に安全な未来の道を築くことです。</p>

                        <p> - バーマン・ナインゴラン</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <div class="custom-text-9-jp">会社の役員</div>
        <v-row class="my-4">
            <v-col v-for="person in people" :key="person.name" cols="12" md="12" class="text-center">
                <v-card flat>
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="3">
                            <v-avatar size="150" class="ma-3" style="border-radius: 12px;">
                                <img :src="person.photoUrl" alt="Photo" style="border-radius: 12px;">
                            </v-avatar>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-text class="text-left">
                                <div class="mb-1 custom-text-3 text-justify">{{ person.name }}</div>
                                <div class="mb-1 custom-text-3 text-justify">{{ person.position }}</div>
                                <div class="mb-1 custom-text-2 text-justify">{{ person.description }}</div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
    async mounted() {
        await auditrail.create({
            action: this.$route.path
        });
    },
    data() {
        return {
            people: [
                {
                    photoUrl: '/images/teachers/berman.webp',
                    name: 'バーマン・ナインゴラン',
                    position: '会長',
                    description: 'バーマン・ナインゴランは、SH、MH、CLAの学位を持つ法律の専門家で、正義のための闘いに専念してきました。LPK AISHIN INDONESIAの会長として、バーマンはリーダーシップの責任を負うだけでなく、強い正義感を持って会社のビジョンを定義します。 JLPT N1の認定を受けており、日本語の能力が強化され、共通の目標を達成するための異文化間の協力関係を強化します。'
                },
                {
                    photoUrl: '/images/teachers/kato.webp',
                    name: '加藤浩介',
                    position: '株式会社EBT代表取締役',
                    description: '株式会社EBTの代表取締役である加藤浩介は、日本とインドネシアの架け橋です。国際関係の背景を持ち、国境を越えた協力の促進に幅広い経験を持つ加藤は、会社のビジョンを示し、まさにコミットメントを持って体現しています。戦略的プロジェクトのマネージャーとして、ビジョンを現実に変える能力は広く認められています。'
                },
                {
                    photoUrl: '/images/teachers/yosua.webp',
                    name: 'ヨシュア・パルメス・シトルス',
                    position: 'コーディネーター',
                    description: '鋭いビジョンと深い専門知識を持つコーディネーターのヨシュア・パルメス・シトルス。東京大学卒業生で、JLPT N1の認定を取得し、ヨシュアは役割に必要な異文化間のコミュニケーションスキルを持ち込みます。さまざまなイニシアチブを率いる中で、ヨシュアは強力なネットワークを築き、関係者全員が相互の成功を確実にすることにコミットしています。'
                },
            ],
        };
    }
};
</script>

<style>
.custom-text-4-jp {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 900;
    font-size: 50px;
    color: #31363F;
    text-align: justify;
    padding-left: 250px;
}
.custom-text-3 {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #31363F;
    text-align: justify;
}
.custom-text-2 {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
}
.custom-text-9-jp {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #31363F;
  text-align: center;
  padding-bottom: 10px;
  line-height: 1.5;
}
</style>
