const BaseModule = {
    namespaced: true,

    state: {
        lastCache: null,
        listCache: null,
        currentLanguage: null,
    },

    getters: {
        lastCache: (state) => state.lastCache,
        listCache: (state) => state.listCache,
        currentLanguage: (state) => state.currentLanguage,
    },

    mutations: {
        lastCache: (state, payload) => state.lastCache = payload,
        listCache: (state, payload) => state.listCache = payload,
        currentLanguage: (state, payload) => state.currentLanguage = payload,
    },
};

export default BaseModule;