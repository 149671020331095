<template>
    <v-app>
      <v-main>
        <h2 class="custom-text-9-id">LOWONGAN PEKERJAAN</h2>
        <v-container>
          <v-card class="lowongan-card">
            <v-row>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col v-for="(job, index) in jobs" :key="index" cols="12" md="4" class="job-column">
                    <div class="center-content">
                      <v-img :src="job.image" aspect-ratio="1" contain class="job-image"></v-img> <!-- Tambahkan class job-image -->
                      <v-card-actions class="register-btn-container">
                        <v-btn @click="register" color="#31363F" dark class="register-btn">Register</v-btn>
                      </v-card-actions>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    data() {
      return {
        jobs: [
          { title: 'Job 1', image: '/images/Stock/j1.png' },
          { title: 'Job 2', image: '/images/Stock/j2.png' },
          { title: 'Job 3', image: '/images/Stock/j3.png' },
          { title: 'Job 4', image: '/images/Stock/j4.png' },
          { title: 'Job 5', image: '/images/Stock/j1.png' },
          { title: 'Job 6', image: '/images/Stock/j2.png' },
        ]
      };
    },
    methods: {
      register() {
        this.goPage('/reservation');
      }
    }
  };
  </script>
  
  <style scoped>
  .lowongan-card {
    background-color: white;
    padding: 20px;
  }
  
  .job-column {
    padding: 10px;
  }
  
  .center-content {
    text-align: center;
  }
  
  .job-image {
    transition: transform 0.3s; /* Tambahkan transisi scaling */
  }
  
  .job-image:hover {
    transform: scale(1.1); /* Scaling saat hover */
  }
  
  .register-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .register-btn {
    width: 100%;
  }
  
  </style>
  