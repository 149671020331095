<template>
  <v-app id="login" class="login-bg">
    <v-main>
      <v-container fill-height>
        <v-row>
          
          <!-- Welcome Text Column -->
          <v-col cols="12" sm="8" md="8" class="welcome-text" order="1">
            <div class="custom-text-login">WELCOME TO LPK AISHIN LOGIN PAGE</div>
          </v-col>
          

          <!-- Login Card Column -->
          <v-col cols="12" sm="4" md="4" order="2">
            <v-card class="login-card elevation-12">
              <v-card-title class="justify-center d-flex flex-column align-center">
                <v-img src="/images/logo-bordered.png" height="50px" contain class="mb-3 custom-text-5"></v-img>
                <span class="title custom-text-5">Sign In</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field id="username" v-model="resource.username" autocomplete="off" class="mb-2 custom-text-5"
                    label="Username" outlined></v-text-field>

                  <v-text-field id="password" v-model="resource.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                    autocomplete="off" class="mb-2 custom-text-5" label="Password" outlined @click:append="togglePassword"
                    @keydown.enter="login"></v-text-field>

                  <v-btn :disabled="loading" :loading="loading" block class="mt-5 custom-text-5" color="black" dark
                    @click="login">Login</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="#222831" padless>
      <v-container fluid>
        <v-card flat>
          <v-row no-gutters justify="center">
            <v-col cols="12" md="1">
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <v-list dense style="text-align: center;">
                    <v-list-item href="/en-us/home">Home</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="1">
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <v-list dense style="text-align: center;">
                    <v-list-item href="/en-us/member">About</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="1">
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <v-list dense style="text-align: center;">
                    <v-list-item href="/en-us/about">Company Profile</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="1">
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <v-list dense style="text-align: center;">
                    <v-list-item href="/en-us/so">Sending Organization</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="1">
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <v-list dense style="text-align: center;">
                    <v-list-item href="/en-us/activity">Educational Activities</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="1">
              <v-card flat>
                <v-card-text class="custom-text-5">
                  <v-list dense style="text-align: center;">
                    <v-list-item href="/en-us/contact">Contact</v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-footer>
  </v-app>
</template>


<script>
import { mapGetters } from "vuex";
import authApi from "../api/AuthApi";
import auditrail from "../api/AuditrailApi";
import Swal from 'sweetalert2';

export default {
  name: "Login",

  data() {
    return {
      resource: {
        username: "",
        password: "",
      },
      showPassword: false,
      loading: false,
      errorMessage: "",
      dialogSetting: false,
      tempBaseURL: null,
      debugDownload: false,
      db: null,
    };
  },

  computed: {
    ...mapGetters({
      baseURL: 'auth/baseURL',
      lastCache: 'base/lastCache',
      listCache: 'base/listCache'
    }),
  },

  async mounted() {
    await auditrail.create({
      action: this.$route.path
    });
  },

  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    async login() {
      try {
        this.loading = true;
        const identity = await authApi.login(this.resource);
        this.loading = false;
        this.storageSave("auth/identity", identity);
        console.log(identity)
        await Swal.fire({
          icon: 'success',
          title: 'Login Success',
          text: 'Welcome to a Special Page',
          showConfirmButton: false,
          timer: 1500
        });
        await this.$router.push("/client/profile");
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message || "Terjadi kesalahan saat login!"
        });
      }
    },

    goHome() {
      this.$router.push('/home');
    },
  },
}
</script>

<style scoped>

.custom-text-login {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #ffffff;
  text-align: left;
}

.custom-text-5 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-align: justify;
}

.custom-text-6 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-align: justify;
}

.login-card {
  border-radius: 20px;
  overflow: hidden;
  transition: box-shadow .3s;
}

.login-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, .25);
}

.login-bg {
  background-color: #222831;
}



.title {
  text-align: center;
  color: #2f2e2e;
  /* Warna biru seperti di gambar */
}
.welcome-text {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* This ensures vertical center alignment */
  padding-right: 64px; /* Adjust the right padding to space out the text from the card */
}
/* Responsive breakpoint adjustments */
@media (max-width: 959px) {
  .welcome-text {
    justify-content: center;
    padding-right: 0;
    text-align: center;
    order: 2; /* Put the welcome text below the login card on smaller screens */
  }
}

/* Ensure the login card and text are on the same row on larger screens */
@media (min-width: 960px) {
  .flex-nowrap {
    flex-wrap: nowrap;
  }
}
</style>
