<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text>
    <h2 class="my-8 custom-text-9-jp">会社概要</h2>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-simple-table class="company-profile-table centered-table custom-text-5">
          <template v-slot:default>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td>会社名</td>
                <td>LPK AISHIN INDONESIA</td>
              </tr>
              <tr>
                <td>会社設立</td>
                <td>June 15, 2015</td>
              </tr>
              <tr>
                <td>法人番号</td>
                <td>73.403.175.0-071.000</td>
              </tr>
              <tr>
                <td>送出機関</td>
                <td>May 12, 2023</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>

<v-row justify="center">
  <v-col cols="12" md="4">
    <v-card flat>
      <v-card-text>
        <h2 class="text-center my-4 custom-text-6">インドネシアのビジネス内容</h2>
        <v-simple-table class="company-profile-table centered-table custom-text-5">
          <template v-slot:default>
            <thead>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>業務委託業（事務員、警備員、運転手）</td>
              </tr>
              <tr>
                <td>労働者派遣業（契約社員、研修生）</td>
              </tr>
              <tr>
                <td>就労ビザ取得代行業</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col cols="12" md="4">
    <v-card flat>
      <v-card-text>
        <h2 class="text-center my-4 custom-text">日本での事業内容</h2>
        <v-simple-table class="company-profile-table centered-table custom-text-6">
          <template v-slot:default>
            <thead>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>日本語研修センター</td>
              </tr>
              <tr>
                <td>外国人技能実習生送出機関</td>
              </tr>
              <tr>
                <td>特定技能者斡旋事業</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>

<h2 class="custom-text-9-jp">許認可</h2>
<v-row justify="center">
  <v-col cols="12" md="8">
    <v-card flat>
      <v-card-text>
        <v-simple-table class="company-profile-table centered-table custom-text-5">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>法人登記</td>
                <td>No.18 Date June 15, 2015 ABDUL RAJAB RAHMAN, SH., M.Kn.</td>
              </tr>
              <tr>
                <td>法人認可</td>
                <td>AHU-2447532.AH.01.01.YEAR 2015</td>
              </tr>
              <tr>
                <td>研修センター許認可</td>
                <td>No.1/G.1/31.75.07.1007.01..K.2/3-.851.332/e/2021</td>
              </tr>
              <tr>
                <td>労働者派遣許認可</td>
                <td>No.503/2383/7IOLPKS/V/DPMPTSP/2021</td>
              </tr>
              <tr>
                <td>研修生派遣許認可</td>
                <td>No.563/KEP.3658/LATTAS/2021</td>
              </tr>
              <tr>
                <td>送出機関許認可</td>
                <td>2/1402/HK.03.01/V/2023</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>

<h2 class="my-8 custom-text-9-jp">業務提携•自治体連携</h2>
<v-container>
      <v-card flat>
        <v-card-text class="text-center custom-text-5">
          <v-row justify="center">
            <v-col cols="12" md="4" lg="4" v-for="partner in partners" :key="partner.name">
              <v-card :color="partner.color" class="d-flex flex-column justify-center align-center pa-5 motto-card hover-scale" flat>
                <v-icon size="64" class="mb-3">mdi-briefcase</v-icon>
                <h3 class="partner-title custom-text-5">{{ partner.name }}</h3>
                <p class="partner-description custom-text-5">{{ partner.description }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
</v-container>

  </v-container>
</template>

  <script>
  import auditrail from "../../../api/AuditrailApi";

  export default {
    async mounted() {
      await auditrail.create({
        action: this.$route.path
      });
    },
    data() {
      return {
        partners: [
          {
            name: 'LPK International Partnership',
            description: '西ジャワ州、ブカシ市'
          },
          {
            name: 'LPK Bogor',
            description: '西ジャワ州、ボゴール市'
          },
          {
            name: 'LPK Semarang',
            description: '中部ジャワ州、スマラン市'
          },

          {
            name: 'LPK Hikari Sumut',
            description: '北スマトラ州、メダン市'
          },

          {
            name: 'LPK Siantar',
            description: '北スマトラ州、シアンタール市'
          },

          {
            name: 'サラティガ市',
            description: '中部ジャワ州'
          },

          {
            name: 'トバ•サモシール県',
            description: '北スマトラ州'
          },


        ],
      };
    }
  };
</script>

  <style>
  .v-icon {
    margin-bottom: 10px;
  }
.company-profile-table tbody tr:hover {
  background-color: transparent !important;
}
.partner-description, .partner-title {
  word-wrap: break-word; /* Memastikan teks pindah baris dan tidak overflow */
  padding: 0 8px; /* Menambahkan padding agar teks tidak menyentuh tepi kolom */
}
.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}


</style>
