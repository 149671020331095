<template>
    <v-container fluid>
        <h2 class="my-4 custom-text-9">EDUCATION CURRICULUM</h2>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-card flat>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card flat class="d-flex justify-center align-center card-content">
                                <img src="/images/Stock/4.webp" class="card-image">
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card class="text-card-content card-content">
                                <ul class="custom-text-2" style="list-style-type: none; padding: 0; margin: 0;">
                                    <li>Japanese Language Learning</li>
                                    <li>Technology and Skills Learning</li>
                                    <li>Japanese Cultural Learning</li>
                                </ul>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card class="text-card-content card-content spacing-top">
                                <ul class="custom-text-2" style="list-style-type: none; padding: 0; margin: 0;">
                                    <li>Physical Fitness Measurement</li>
                                    <li>Increase Physical Strength</li>
                                    <li>Morning Exercise</li>
                                </ul>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card flat class="d-flex justify-center align-center card-content">
                                <img src="/images/Stock/5.webp" class="card-image">
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card flat class="d-flex justify-center align-center card-content">
                                <img src="/images/Stock/6.webp" class="card-image">
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card class="text-card-content card-content">
                                <ul class="custom-text-2" style="list-style-type: none; padding: 0; margin: 0;">
                                    <li>Psychological Test</li>
                                    <li>Candidate Exam</li>
                                    <li>Election Test</li>
                                    <li>Japanese Language Proficiency Test</li>
                                </ul>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card class="text-card-content card-content spacing-top">
                                <ul class="custom-text-2" style="list-style-type: none; padding: 0; margin: 0;">
                                    <li>Medical Check Up</li>
                                </ul>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card flat class="d-flex justify-center align-center card-content">
                                <img src="/images/Stock/7.webp" class="card-image">
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card flat class="d-flex justify-center align-center card-content">
                                <img src="/images/Stock/8.webp" class="card-image">
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card class="text-card-content card-content">
                                <ul class="custom-text-2" style="list-style-type: none; padding: 0; margin: 0;">
                                    <li>Recommendation Filtering</li>
                                    <li>Interview</li>
                                </ul>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <div style="height: 3cm;"></div>
        <h2 class="my-4 custom-text-9">TRAINING FACILITIES</h2>
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" md="3" v-for="(item, index) in galleryItems" :key="index">
                    <v-card flat>
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-img :src="item.src" class="d-flex transition-slow" height="auto"
                                    :class="{ 'scale-hover': hover }">
                                    <v-overlay :value="hover" absolute>
                                        <v-card class="d-flex flex-column justify-center align-center"
                                            color="rgba(0, 0, 0, 0.5)" flat>
                                            <span class="white--text headline">{{ item.title }}</span>
                                        </v-card>
                                    </v-overlay>
                                </v-img>
                            </template>
                        </v-hover>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <div style="height: 3cm;"></div>
        <h2 class="my-4 custom-text-9">AFFILIATE FACILITIES [ LPK HIKARI ]</h2>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-card flat>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card flat class="d-flex justify-center align-center card-content-1">
                                <img src="/images/Gallery/Picture16.webp" class="card-image">
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card class="text-card-content card-content-1" flat>
                                <ul class="custom-text-2" style="list-style-type: none; padding: 0; margin: 0;">
                                    <li>Japanese Language Learning Atmosphere</li>
                                </ul>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card class="text-card-content card-content-1 spacing-top" flat>
                                <ul class="custom-text-2" style="list-style-type: none; padding: 0; margin: 0;">
                                    <li>Discussion in Japanese Language</li>
                                </ul>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card flat class="d-flex justify-center align-center card-content-1">
                                <img src="/images/Gallery/Picture17.webp" class="card-image">
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <div style="height: 3cm;"></div>
    </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
    data() {
        return {
            galleryItems: [
                { src: '/images/Gallery/Picture14.webp', title: 'Learning Atmosphere' },
                { src: '/images/Gallery/Picture8.webp', title: 'Japanese Instructor' },
                { src: '/images/Gallery/Picture15.webp', title: 'Class Room' },
                { src: '/images/Gallery/Picture9.webp', title: 'Staff Room' },
                { src: '/images/Gallery/Picture10.webp', title: 'Japanese Learning Center' },
                { src: '/images/Gallery/Picture11.webp', title: 'Job Opportunity' },
                { src: '/images/Gallery/Picture12.webp', title: '5S' },
                { src: '/images/Gallery/Picture13.webp', title: 'Horenso' }
            ],
        };
    },
    mounted() {
        auditrail.create({
            action: this.$route.path
        });
    },
}
</script>

<style>
.custom-text {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
}

.company-profile-table th,
.company-profile-table td {
    border: 1px solid black;
}

.company-profile-table thead .black-header {
    background-color: #000;
    color: #fff;
}

/* Ensure border-collapse applied for neat border appearance */
.company-profile-table {
    border-collapse: collapse;
}

/* Add right padding for the first column and left padding for the second column */
.pr-2 {
    padding-right: 0.5rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.large-text {
    font-size: 18px;
    font-family: 'Calibri', sans-serif;
    /* Using Calibri font */
    font-weight: 100;
    /* Light style */
    font-size: 22px;
    /* Font size 28px */
    text-align: justify;
    /* Justified alignment */
    line-height: 1.5;
}

.custom-h2 {
    font-size: 24px;
    /* Larger text size */
    color: red;
    /* Red color */
    padding-top: 20px;
    /* Top padding distance */
}

.v-avatar img {
    object-fit: cover;
}

.v-img {
    max-height: 100px;
    /* Adjust the height as needed */
}

.text-right-align {
    text-align: right;
}

.text-left-align {
    text-align: left;
}

.text-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    /* Menambahkan transform ke transisi */
    cursor: pointer;
    /* Optional: Mengubah cursor menjadi pointer saat di-hover untuk memberi indikasi bisa diklik atau di-interaksi */
}

.text-card-content:hover {
    background-color: black;
    /* Warna latar belakang saat hover */
    color: white;
    /* Warna teks saat hover */
    transform: scale(1.05);
    /* Membuat kartu sedikit lebih besar */
}

/* Atur margin antar v-card */
.spacing-1cm {
    margin: 10px;
    /* Setara dengan sekitar 1cm tergantung pada DPI layar */
}

.card-content {
    height: 250px;
    width: 460px;
    /* Sesuaikan ini sesuai dengan ukuran yang diinginkan */
}

.card-content-1 {
    height: 200px;
    width: 520px;
    /* Sesuaikan ini sesuai dengan ukuran yang diinginkan */
}

.card-image {
    max-width: 100%;
    max-height: 100%;
    width: 600px;
    /* Pastikan ini sama dengan lebar .card-content */
}

.text-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-img {
    transition: transform 0.5s ease;
    cursor: pointer;
}

.v-img:hover {
    transform: scale(1.05);
}

.v-card-title {
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.5s ease;
}


.v-img:hover {
    transform: scale(1.05);
}


.transition-slow {
  transition: transform 0.5s ease-in-out;
}

.scale-hover {
  transform: scale(1.05);
}
</style>
