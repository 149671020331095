<template>
  <v-main>
    <v-container>
      <v-card-title class="custom-text-4">Website Activity</v-card-title>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40"
  transition="scale-transition" offset-y min-width="290px">
  <template v-slot:activator="{ on, attrs }">
    <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
      v-on="on"></v-text-field>
  </template>
  <v-date-picker v-model="startDate" :max="endDate || new Date().toISOString().substr(0, 10)" @input="menuStartDate = false"></v-date-picker>
</v-menu>
<v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40"
  transition="scale-transition" offset-y min-width="290px">
  <template v-slot:activator="{ on, attrs }">
    <v-text-field v-model="endDate" label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
      v-on="on"></v-text-field>
  </template>
  <v-date-picker v-model="endDate" :min="startDate" @input="menuEndDate = false"></v-date-picker>
</v-menu>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="exportToExcel">Export to Excel</v-btn>
        </v-card-title>
      </v-card>


      <!-- Tabel Daftar Member -->
      <v-card>
        <v-data-table :headers="headers" :items="filteredMembers" :items-per-page="10" :search="search" multi-sort
          class="elevation-1" :page.sync="page" :page-count="pageCount">
          <template v-slot:item.actionTime="{ item }">
            {{ ReportDate(item.actionTime) }}
          </template>
        </v-data-table>
      </v-card>

      <!-- Dialog/Pop-up untuk View Profile -->
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title class="headline">{{ selectedMember.name }}</v-card-title>
          <v-card-text>
            <!-- Tampilkan detail profil di sini -->
            <!-- ... -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>
<script>
import auditrail from "../../api/AuditrailApi";
import { utils, writeFile } from 'xlsx'; // Perbaikan import

export default {
  data() {
    return {
      members: [],
      dialog: false,
      selectedMember: {},
      search: '',
      startDate: null,
      endDate: null,
      menuStartDate: false,
      menuEndDate: false,
      headers: [
        { text: 'User', value: 'user.username' },
        { text: 'Action', value: 'action' },
        { text: 'Action Time', value: 'actionTime' },
      ],
    };
  },
  watch: {
    startDate(val) {
      if (new Date(this.endDate) < new Date(val)) {
        this.endDate = null;
      }
    },
    endDate(val) {
      if (new Date(this.startDate) > new Date(val)) {
        this.startDate = null;
      }
    }
  },
  computed: {
    filteredMembers() {
      if (!this.startDate || !this.endDate) return this.members;
      const start = new Date(this.startDate).getTime();
      const end = new Date(this.endDate).getTime();
      return this.members.filter(member => {
        const actionTime = new Date(member.actionTime).getTime();
        return actionTime >= start && actionTime <= end;
      });
    }
  },
  methods: {
    exportToExcel() {
      const data = this.filteredMembers.map(member => {
        const username = member.user ? member.user.username : "Non Account User"; // Asumsikan fallback jika tidak ada user
        const action = member.action || "No action"; // Asumsikan fallback jika tidak ada action
        const actionTime = member.actionTime ? this.ReportDate(member.actionTime) : "No date"; // Asumsikan fallback jika tidak ada actionTime
        return {
          Username: username,
          Action: action,
          ActionTime: actionTime
        };
      });

      const worksheet = utils.json_to_sheet(data);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Report');
      writeFile(workbook, 'WebsiteActivityReport.xlsx');
    },

    async loadData() {
      try {
        const response = await auditrail.getList();
        this.members = response;
      } catch (error) {
        console.error(error);
      }
    },
    viewProfile(member) {
      this.selectedMember = member;
      this.dialog = true;
    },
    ReportDate(date) {
      return new Date(date).toLocaleDateString();
    }
  },
  async mounted() {
    this.loadData();
  }
};
</script>

<style scoped>
.v-footer {
  color: white;
}

.v-card {
  margin-top: 1cm;
  margin-bottom: 1cm;
}
</style>
