<template>
    <v-container fluid>
        <h2 class="text-center my-4 custom-h2">BROCHURE</h2>
        <v-row align="center" justify="center">
            <v-col cols="12" md="3" class="pl-10">
                <!-- For Image -->
                <v-img src="/images/coverbrocure.png" aspect-ratio="0.8" class="grey lighten-2"
                    :class="{ 'elevation-5': true }" style="border: 1px solid black;">
                    <!-- You can add overlay or text on top of the image here if needed -->
                </v-img>
            </v-col>

            <v-col cols="12" md="6">
                <v-card class="my-16" flat>
                    <v-card-text class="large-text px-8 py-12">
                        Download the LPK Aishin Brochure to get more detailed information about LPK Aishin.
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center py-3">
                        <v-btn color="primary" depressed @click="downloadPdf">
                            Download
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-container fluid>
            <!-- Margin top class added here for spacing from the content above -->
            <v-card flat class="mt-5">
                <v-row no-gutters justify="center">
                    <v-col cols="12" md="4" class="d-flex justify-center align-center">
                        <!-- Brand Logo and Description -->
                        <v-row>
                            <v-col class="d-flex justify-center justify-md-start py-3">
                                <img src="/images/logo-bordered.png" alt="AISHIN Group Logo" height="150">
                                <!-- Add your logo image here -->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="d-flex justify-center justify-md-start py-3">
                                <!-- Logos for certifications or partners can go here -->
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="2">
                        <!-- Pages Section -->
                        <v-card flat>
                            <v-card-text class="large-text">
                                <div class="footer-heading">Pages</div>
                                <v-list dense>
                                    <v-list-item href="/en-us/home">Home</v-list-item>
                                    <v-list-item href="/en-us/member">Our Members</v-list-item>
                                    <v-list-item href="/en-us/about">About Us</v-list-item>
                                    <v-list-item href="/en-us/so">Sending Organization</v-list-item>
                                    <v-list-item href="/en-us/activity">Educational Activities</v-list-item>
                                    <v-list-item href="/en-us/contact">Contact</v-list-item>
                                    <v-list-item href="/en-us/faq">FAQ</v-list-item>
                                    <v-list-item href="/en-us/career">Career</v-list-item>
                                    <v-list-item href="/en-us/brocure">Brochure</v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                        <!-- Address Section -->
                        <v-card flat>
                            <v-card-text class="large-text">
                                <div class="footer-heading">Address</div>
                                <div>Jl. Terusan I Gusti Ngurah Rai No.1, Pd. Kopi, Kec. Duren Sawit, East Jakarta City,
                                    Special Capital
                                    Region of Jakarta 13460</div>
                                <div>Phone: (021) 8974540</div>
                                <div>Email: info@aishingroup.co.id</div>
                                <div>Office Hours: 08:00 - 17:00</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-container>
</template>
  
<script>
import auditrail  from "../../../api/AuditrailApi";

export default {
  async mounted(){
    await auditrail.create({
      action: this.$route.path
    });
  },
    name: 'CustomLayout',

    methods: {
        downloadPdf() {
            const path = '/images/brosur.pdf'; // Adjust this to the actual location of your pdf brochure
            const link = document.createElement('a');
            link.href = path;
            link.setAttribute('download', 'brochure.pdf'); // Set the file name when downloaded
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>
  
<style>
.large-text {
    font-size: 18px;
    /* You have two font-size in this class, the last one will be applied */
    font-family: 'Calibri', sans-serif;
    font-weight: 100;
    font-size: 22px;
    /* This will override the font-size 18px above */
    text-align: justify;
    line-height: 1.5;
}

/* Additional styles if needed */
</style>
