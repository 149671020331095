<template>
  <v-menu
      ref="open"
      v-model="open"
      :close-on-content-click="false"
      :nudge-top="15"
      max-width="290"
      offset-y
      transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="displayValue"
          v-bind="attrs"
          v-bind:backgroundColor="backgroundColor"
          v-bind:clearable="clearable"
          v-bind:dense="dense"
          v-bind:disabled="disabled"
          v-bind:error="error"
          v-bind:error-messages="errorMessages"
          v-bind:hide-details="hideDetails"
          v-bind:label="label"
          v-bind:outlined="outlined"
          v-bind:placeholder="placeholder"
          v-bind:readonly="readonly"
          v-bind:rules="rules"
          v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker v-model="dateValue" no-title width="290" @input="open = false"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Date, String],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "dd/MM/yyyy",
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    rules: {
      type: [Array, String],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    properties: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data: () => ({
    open: false,
  }),

  computed: {
    displayValue: {
      get: function () {
        return this.format(this.value);
      },
      set: function (newValue) {
        this.dateValue = this.parse(newValue);
      },
    },
    dateValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    format(date) {
      if (!date) return "";
      const [year, month, day] = date.substring(0, 10).split("-");
      if(date.includes("/"))
        return this.formatDate(date)
      else
      return `${day}/${month}/${year}`;
    },
    parse(date) {
      if (!date) return null;
      // const [day, month, year] = date.split("/");
      // return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      return this.formatDate(date)
    },
  },
};
</script>