<template>
  <v-container fluid>
    <v-layout row justify-center mt-5>
      <v-flex xs12 sm12 md9>
        <v-carousel height="500" cycle :interval="3000" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(item, i) in sliders" :key="i" :src="item.src" reverse-transition="fade-transition"
            transition="fade-transition" align-start>
            <v-container fluid>
              <v-row class="fill-height pa-5">
                <v-col cols="12" md="6" class="d-flex flex-column justify-center align-start">
                  <transition name="fade" mode="out-in">
                    <div key="item.title" class="text-container" v-if="i === 1 || i === 2">
                      <h3 class="mb-2 custom-text-7">{{ item.title }}</h3>
                      <p class="custom-text-8">{{ item.description }}</p>
                      <v-btn text v-if="i === 1" class="mt-4 custom-text-8" @click="goPage('/login')">Login
                        &gt;&gt;&gt;</v-btn>
                      <v-btn text v-if="i === 2" class="mt-4 custom-text-8" @click="goPage('/reservation')">Daftar
                        Sekarang
                        &gt;&gt;&gt;</v-btn>
                    </div>
                  </transition>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <!-- Gambar atau konten lain untuk sisi kanan -->
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
    <div style="height: 2cm;"></div>
    <v-container fluid>
      <v-row align="center" justify="center" class="my-4">
        <!-- Kolom untuk Logo -->
        <v-col cols="12" sm="3" class="text-center">
          <img src="/images/logo-bordered.png" style="max-width: 100%; height: auto;">
        </v-col>

        <!-- Kolom untuk Judul/Sub Judul dan Teks Tambahan -->
        <v-col cols="12" sm="6" class="text-center">
          <div>
            <h1 class="custom-text-7 black--text">LPK AISHIN INDONESIA</h1>
            <h2 class="custom-text-3 black--text">LEMBAGA PELATIHAN KERJA INDONESIA</h2>
            <p class="custom-text-3 black--text">
              BERGABUNGLAH DENGAN LANGKAH-LANGKAH, MASA DEPAN CERAH INDONESIA DAN JEPANG MEMBANGUN MIMPI-MIMPI</p>
          </div>
        </v-col>
      </v-row>

      <!-- Baris untuk Motto -->
      <v-row justify="center" class="my-4">
      <v-col cols="12" md="8">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="3" v-for="(motto, index) in mottos" :key="index">
            <v-card :color="motto.color" class="d-flex flex-column justify-center align-center pa-5 motto-card">
              <v-icon size="48" color="#ffffff">{{ motto.icon }}</v-icon>
              <div class="motto-text">{{ motto.text }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </v-container>

    <div class="my-4 custom-text-9" style="margin-top: 100px;">APA ITU LPK AISHIN INDONESIA ?</div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <v-row align="center">

            <!-- Kolom untuk foto -->
            <v-col cols="12" md="4" style="text-align: center;">
              <img src="/images/teachers/berman.webp" style="max-width: 60%; height: auto;">
            </v-col>

            <!-- Kolom untuk teks -->
            <v-col cols="12" md="8">

              <v-card-text class="custom-text-2">
                <p>Sebagai lembaga yang secara resmi diakui oleh Kementerian Ketenagakerjaan Republik Indonesia, LPK
                  AISHIN INDONESIA bertekad untuk menerapkan sistem hukum Jepang dan Indonesia dalam pengelolaan bisnis
                  penyediaan tenaga kerja, dengan tunduk sepenuhnya pada regulasi hukum yang berlaku.</p>
                <p>Selama lebih dari satu dekade, keahlian kami dalam bisnis penyediaan tenaga kerja di Indonesia telah
                  menjadi pilihan utama bagi perusahaan-perusahaan Jepang yang memperluas bisnis mereka di Indonesia.
                  Mereka mempercayakan kami untuk memperoleh pekerja kontrak dan peserta magang.</p>
                <p>Kami bekerja sama erat dengan berbagai agen penyediaan lainnya dan lembaga pendidikan bahasa Jepang
                  di Indonesia, serta berkolaborasi dengan pemerintah daerah, untuk memastikan ketersediaan sumber daya
                  manusia berkualitas yang memenuhi kebutuhan perusahaan dengan cepat dan akurat. Setiap hari, kami
                  berusaha dengan tekun untuk terus memperkuat kepercayaan sebagai organisasi penyedia tenaga kerja yang
                  aman dan andal bagi dunia bisnis.</p>
              </v-card-text>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-layout row wrap>
      <v-flex xs12>
        <div class="custom-text-9" style="margin-bottom: 60px;">Aktivitas Pemeliharaan Legalitas</div>
      </v-flex>
      <v-card flat>
        <v-layout row wrap>
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 sm3>
              <v-card flat class="d-flex flex-column align-center justify-center">
                <v-img src="/images/Home/Home-1.webp" style="max-width: 80%; max-height: 250px;"
                  class="grey lighten-2"></v-img>
                <v-card-text class="custom-text">Perizinan Kantor Tenaga Kerja</v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 sm3>
              <v-card flat class="d-flex flex-column align-center justify-center">
                <v-img src="/images/Home/Home-2.webp" style="max-width: 80%; max-height: 200px;"
                  class="grey lighten-2"></v-img>
                <v-card-text class="custom-text">Kegiatan Listening Oleh Siswa</v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card>
    </v-layout>

    <div style="height: 2cm;"></div>
    <div class="my-4 custom-text-9" style="margin-top: 100px;">Testimoni Alumni</div>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="mx-auto" flat>
          <v-carousel v-model="carousel" hide-delimiters show-arrows cycle height="400px" class="elevation-2">
            <v-carousel-item v-for="(feedback, index) in feedbacks" :key="index">
              <v-row no-gutters align="center" class="fill-height">
                <v-col cols="4" class="d-flex justify-center align-center">
                  <v-avatar size="120" class="elevation-6">
                    <!-- Contoh menggunakan gambar dari placeholder.com sebagai alternatif -->
                    <v-img :src="feedback.image" class="br-20"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="8" class="d-flex align-center">
                  <div class="feedback-text">{{ feedback.text }}</div>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import auditrail from "../../../api/AuditrailApi";

export default {
  async mounted() {
    await auditrail.create({
      action: this.$route.path
    });
  },
  data() {
    return {
      mottos: [
        { text: "SEMANGAT", icon: "mdi-fire"},
        { text: "KESABARAN",  icon: "mdi-timer-sand" },
        { text: "RENDAH HATI", icon: "mdi-hands-pray" },
        { text: "AMBISI", icon: "mdi-flag-checkered" },
        { text: "KEPERCAYAAN", icon: "mdi-handshake" },
        { text: "TANPA BATASAN", icon: "mdi-infinity" },
        { text: "TANTANGAN", icon: "mdi-trophy" },
      ],
      sliders: [
        {
          src: '/images/Stock/2.webp',
          title: '',
          description: ''
        },
        {
          src: '/images/Stock/1.1.webp',
          title: 'Jepang-Indonesia',
          description: 'Buka peluang tenaga kerja Indonesia yang menjanjikan untuk perusahaan Anda. Mulai perjalanan Anda—Login untuk terhubung.',
        },
        {
          src: '/images/Stock/2.2.webp',
          title: 'Jepang Menanti: Mulai Karir Global Anda',
          description: 'Ambil kesempatan untuk berkembang dengan karir di Jepang. Bergabunglah dengan LPK Aishin, di mana perjalanan Anda menuju kesuksesan internasional dimulai.',
        },
      ],

      carousel: 0,
      feedbacks: [
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHIN telah menjadi penopang perkembangan karier saya. Bimbingan mereka tidak tertandingi.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Peluang yang diberikan LPK AISHIN telah mengubah hidup profesional saya. Sebuah mitra sejati dalam pertumbuhan.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Dukungan luar biasa dari LPK AISHIN! Komitmen mereka terhadap keunggulan terlihat dalam semua yang mereka lakukan.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Dengan LPK AISHIN, saya menemukan lebih dari sekadar pekerjaan; saya menemukan jalur karier di Jepang. Selamanya bersyukur.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Profesional, mendukung, dan berdedikasi. LPK AISHIN menonjol sebagai mercusuar bagi mereka yang ingin bekerja di Jepang.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Tim LPK AISHIN benar-benar peduli terhadap kesuksesan Anda. Dukungan mereka sangat penting bagi saya.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Bekerja dengan LPK AISHIN telah mengubah permainan. Keahlian mereka dalam menjembatani budaya sangat mengesankan.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHIN bukan hanya sebuah organisasi; ini adalah pintu gerbang untuk memenuhi impian Anda di Jepang.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Profesionalisme dan dedikasi LPK AISHIN tidak tertandingi. Sangat direkomendasikan bagi siapa pun yang ingin bekerja di Jepang.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Menemukan LPK AISHIN adalah titik balik dalam karier saya. Bimbingan mereka membuat transisi saya ke bekerja di Jepang lancar.'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'Sejak awal, LPK AISHIN telah menjadi mitra tak ternilai. Keahlian dan dukungan mereka kunci kesuksesan saya di Jepang.'
        }
      ],
    };
  },
  methods: {
    goPage(path) {
      this.$router.push(path); // Memastikan kamu sudah mengonfigurasi vue-router
    },
    next() {
      const next = this.carousel + 1;
      this.carousel = next % this.feedbacks.length;
    },
    prev() {
      const prev = this.carousel - 1;
      this.carousel = prev >= 0 ? prev : this.feedbacks.length - 1;
    },
  },
};
</script>

<style scoped>
.feedback-text {
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
}

.v-carousel {
  border: 2px solid #E0E0E0;
  border-radius: 20px;
}

.text-container {
  padding-top: 50px;
  padding-left: 50px;
}

.v-btn {
  text-decoration: underline;
  padding-left: 0;
}

.custom-text-7 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 42px;
  color: white;
  text-align: justify;
}

.custom-text-8 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  text-align: justify;
}

.custom-text-9 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #900C27;
  text-align: center;
  padding-bottom: 10px;
}

.carousel-container {
  max-width: 100%;
  padding-top: 100px;
}

.v-carousel {
  margin: 0 auto;
  padding-top: 10px;
}

.motto-card {
  height: 200px;
  /* Tentukan tinggi tetap untuk kotak */
  width: 100%;
  /* Agar lebar mengisi kolom */
  margin-bottom: 16px;
  /* Jarak antar kotak */
  background-color: #E0A75E;
  color: white; /* Agar teksnya terlihat di atas background warna maroon */
}

.motto-year {
  font-size: 24px;
  /* Ukuran teks untuk tahun */
  color: black;
  /* Warna teks untuk tahun */
  margin-bottom: 8px;
  /* Jarak antara tahun dan teks motto */
}

.motto-text {
  font-size: 18px;
  color: white; /* Agar teksnya terlihat di atas background warna maroon */
  text-transform: uppercase;
  padding: 0 10px;
}

/* Menambahkan media query untuk responsivitas */
@media (max-width: 600px) {
  .motto-card {
    height: 150px;
    /* Kotak lebih kecil untuk layar yang lebih kecil */
  }
}

.motto-card {
  transition: all 0.3s ease;
}

.motto-card:hover {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
  transform: scale(1.05);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active di <2.1.8 */
  {
  opacity: 0;
}
</style>
