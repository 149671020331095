import Vue from "vue";
import VueRouter from "vue-router";
import AppLayout from "@src/layouts/AppLayout.vue";

import LayoutId from "@src/views/public/id/Layout.vue";
import LayoutEn from "@src/views/public/en-us/Layout.vue";
import LayoutJp from "@src/views/public/ja/Layout.vue";
import HomeId from "@src/views/public/id/Home.vue";
import MemberId from "@src/views/public/id/Member.vue";
import AboutId from "@src/views/public/id/About.vue";
import SOId from "@src/views/public/id/SO.vue"
import ActivityId from "@src/views/public/id/Activity.vue"
import ContactId from "@src/views/public/id/Contact.vue";
import BrocureId from "@src/views/public/id/Brocure.vue";
import FAQId from "@src/views/public/id/FAQ.vue";
import CareerId from "@src/views/public/id/Career.vue";
import ReservationId from "@src/views/public/id/Reservation.vue";
import HomeEn from "@src/views/public/en-us/Home.vue";
import MemberEn from "@src/views/public/en-us/Member.vue";
import AboutEn from "@src/views/public/en-us/About.vue";
import SOEn from "@src/views/public/en-us/SO.vue"
import ActivityEn from "@src/views/public/en-us/Activity.vue"
import ContactEn from "@src/views/public/en-us/Contact.vue";
import BrocureEn from "@src/views/public/en-us/Brocure.vue";
import HomeJp from "@src/views/public/ja/Home.vue";
import MemberJp from "@src/views/public/ja/Member.vue";
import AboutJp from "@src/views/public/ja/About.vue";
import SOJp from "@src/views/public/ja/SO.vue"
import ActivityJp from "@src/views/public/ja/Activity.vue"
import ContactJp from "@src/views/public/ja/Contact.vue";
import BrocureJp from "@src/views/public/ja/Brocure.vue";
import Login from "@src/views/Login.vue";
import Home from "@src/views/public/Home.vue";
import Recruitment from "@src/views/public/Recruitment.vue";
import Profile from "../views/client/profile.vue";
import RegClient from "../views/client/RegClient.vue";
import ClientList from "../views/client/ClientList.vue";
import LPKMember from "../views/client/LPKMember.vue";
import AddMember from "../views/client/AddMember.vue";
import AuditrailReport from "../views/client/AuditrailReport.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Home,
        children: [
            {
                path: "/recruitment",
                component: Recruitment,
            },
        ],
    },
    {
        path: "/id",
        component: LayoutId,
        children: [

            {
                path: "home",
                component: HomeId,
            },

            {
                path: "member",
                component: MemberId,
            },

            {
                path: "about",
                component: AboutId,
            },
            {
                path: "so",
                component: SOId,
            },
            {
                path: "activity",
                component: ActivityId,
            },
            {
                path: "contact",
                component: ContactId,
            },
            {
                path: "brocure",
                component: BrocureId,
            },
            {
                path: "faq",
                component: FAQId,
            },
            {
                path: "career",
                component: CareerId,
            },
            {
                path: "/reservation",
                component: ReservationId,
            },

        ],
    },
    {
        path: "/en-us",
        component: LayoutEn,
        children: [

            {
                path: "home",
                component: HomeEn,
            },
            {
                path: "member",
                component: MemberEn,
            },
            {
                path: "activity",
                component: ActivityEn,
            },

            {
                path: "about",
                component: AboutEn,
            },
            {
                path: "so",
                component: SOEn,
            },
            {
                path: "contact",
                component: ContactEn,
            },
            {
                path: "brocure",
                component: BrocureEn,
            },
        ],
    },
    {
        path: "/ja",
        component: LayoutJp,
        children: [

            {
                path: "home",
                component: HomeJp,
            },
            {
                path: "member",
                component: MemberJp,
            },
            {
                path: "activity",
                component: ActivityJp,
            },
            {
                path: "so",
                component: SOJp,
            },
            {
                path: "brocure",
                component: BrocureJp,
            },
            {
                path: "about",
                component: AboutJp,
            },
            {
                path: "contact",
                component: ContactJp,
            },
        ],
    },

    {
        path: "/login",
        component: Login,
    },
    {
        path: "/client",
        component: AppLayout,
        children: [
            {
                path: "profile",
                component: Profile,
            },
            {
                path: "/regclient",
                component: RegClient,
            },
            {
                path: "/clientlist",
                component: ClientList,
            },
            {
                path: "/lpkmember",
                component: LPKMember,
            },
            {
                path: "/addmember",
                component: AddMember,
            },
            {
                path: "/auditrailreport",
                component: AuditrailReport,
            },
        ]
    }

];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
