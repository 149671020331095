<template>
  <v-container fluid>
    <v-card flat>
    <v-card-text>
    <h2 class="my-12 custom-text-9">COMPANY PROFILE</h2>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-simple-table class="company-profile-table centered-table custom-text-5">
          <template v-slot:default>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td>Company Name</td>
                <td>LPK AISHIN INDONESIA</td>
              </tr>
              <tr>
                <td>Establishment Date</td>
                <td>June 15, 2015</td>
              </tr>
              <tr>
                <td>Company Number</td>
                <td>73.403.175.0-071.000</td>
              </tr>
              <tr>
                <td>Sending Organization</td>
                <td>May 12, 2023</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>

<v-row justify="center">
  <v-col cols="12" md="4">
    <v-card flat>
      <v-card-text>
        <h2 class="text-center my-4 custom-text-6">BUSINESS CONTENTS IN INDONESIA</h2>
        <v-simple-table class="company-profile-table centered-table custom-text-5">
          <template v-slot:default>
            <thead>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Outsourcing Business</td>
              </tr>
              <tr>
                <td>Manpower Sending Business</td>
              </tr>
              <tr>
                <td>Visa Work Ccquisition Agent</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col cols="12" md="4">
    <v-card flat>
      <v-card-text>
        <h2 class="text-center my-4 custom-text">BUSINESS CONTENTS IN JAPAN</h2>
        <v-simple-table class="company-profile-table centered-table custom-text-6">
          <template v-slot:default>
            <thead>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Japanese language training center</td>
              </tr>
              <tr>
                <td>Foreign technical intern job-sending organization</td>
              </tr>
              <tr>
                <td>Specific skilled worker recruitment business</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>

<h2 class="custom-text-9">LICENSES & PERMITS</h2>
<v-row justify="center">
  <v-col cols="12" md="8">
    <v-card flat>
      <v-card-text>
        <v-simple-table class="company-profile-table centered-table custom-text-5">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Company Registration</td>
                <td>No.18 Date June 15, 2015 ABDUL RAJAB RAHMAN, SH., M.Kn.</td>
              </tr>
              <tr>
                <td>Company Permit</td>
                <td>AHU-2447532.AH.01.01.YEAR 2015</td>
              </tr>
              <tr>
                <td>Training Center Permit</td>
                <td>No.1/G.1/31.75.07.1007.01..K.2/3-.851.332/e/2021</td>
              </tr>
              <tr>
                <td>Worker Departure Permit</td>
                <td>No.503/2383/7IOLPKS/V/DPMPTSP/2021</td>
              </tr>
              <tr>
                <td>Training Participant Dispatch Permit</td>
                <td>No.563/KEP.3658/LATTAS/2021</td>
              </tr>
              <tr>
                <td>Dispatcher Operation Permit</td>
                <td>2/1402/HK.03.01/V/2023</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>

<h2 class="my-8 custom-text-9">BUSINESS PARTNER</h2>
<v-container>
      <v-card flat>
        <v-card-text class="text-center custom-text-5">
          <v-row justify="center">
            <v-col cols="12" md="4" lg="4" v-for="partner in partners" :key="partner.name">
              <v-card :color="partner.color" class="d-flex flex-column justify-center align-center pa-5 motto-card hover-scale" flat>
                <v-icon size="64" class="mb-3">mdi-briefcase</v-icon>
                <h3 class="partner-title custom-text-5">{{ partner.name }}</h3>
                <p class="partner-description custom-text-5">{{ partner.description }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
</v-container>

  </v-container>
</template>

  <script>
  import auditrail from "../../../api/AuditrailApi";

  export default {
    async mounted() {
      await auditrail.create({
        action: this.$route.path
      });
    },
    data() {
      return {
        partners: [
          {
            name: 'LPK International Partnership Kota Bekasi',
            description: 'West Java'
          },
          {
            name: 'LPK Bogor',
            description: 'Bogor City, West Java Province'
          },
          {
            name: 'LPK Semarang',
            description: 'Central Java'
          },

          {
            name: 'LPK Hikari North Sumatra',
            description: 'Medan City, North Sumatra Province'
          },

          {
            name: 'LPK Siantar',
            description: 'North Sumatra'
          },

          {
            name: 'Salatiga City',
            description: 'Central Java Province'
          },

          {
            name: 'Toba Samosir Regency',
            description: 'North Sumatra'
          },


        ],
      };
    }
  };
</script>

  <style>
  .v-icon {
    margin-bottom: 10px;
  }
.company-profile-table tbody tr:hover {
  background-color: transparent !important;
}
.partner-description, .partner-title {
  word-wrap: break-word; /* Memastikan teks pindah baris dan tidak overflow */
  padding: 0 8px; /* Menambahkan padding agar teks tidak menyentuh tepi kolom */
}
.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}


</style>
