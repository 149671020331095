<template>
  <v-main>
    <v-container>
      <v-card-title class="custom-text-4">Account List</v-card-title>
      <!-- Kartu Pencarian -->
      <v-card class="mb-5">
        <v-card-title>
          Search
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Account..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-text>
      </v-card>

      <!-- Tabel Daftar Klien -->
      <v-card>
        <v-card-title>
          Account List
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="filteredClients"
          :search="search"
          class="elevation-1"
          :loading="loading"
          loading-text="Loading... Please Wait"
          dense
        >
          <template v-slot:item.startDate="{ item }">
            {{ formatDate(item.startDate) }}
          </template>
          <template v-slot:item.endDate="{ item }">
            {{ formatDate(item.endDate) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>



<script>
import userApi from "../../api/UserApi";
import auditrail from "../../api/AuditrailApi";

export default {
  data() {
    return {
      search: '',
      loading: false,
      clients: [],
      headers: [
        { text: 'Username', value: 'username' },
        { text: 'Company Name', value: 'companyName' },
        { text: 'Company Address', value: 'companyAddress' },
        { text: 'Phone Number', value: 'phoneNumber' },
        { text: 'Contact Person', value: 'contactPerson' },
        { text: 'Email', value: 'contactEmail' },
        { text: 'Contract Start Date', value: 'startDate' },
        { text: 'Contract Start Date', value: 'endDate' },
        { text: 'Account Type', value: 'roleCode' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    filteredClients() {
      return this.clients.filter(client => 
        Object.values(client).some(value => 
          value.toString().toLowerCase().includes(this.search.toLowerCase())
        )
      );
    }
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    },
    async loadClients() {
      this.loading = true;
      try {
        const response = await userApi.getList();
        this.clients = response;
      } catch (error) {
        console.error("Terjadi kesalahan saat memuat data: ", error);
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      console.log("Edit item: ", item);
    },
    deleteItem(item) {
      console.log("Delete item: ", item);
    },
  },
  async mounted() {
    await this.loadClients();
    await auditrail.create({
      action: this.$route.path
    });
  }
};
</script>


<style scoped>
.v-footer {
  color: white;
}

.v-card {
  margin-top: 1cm;
  margin-bottom: 1cm;
}
</style>
