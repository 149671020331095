import XLSX from "xlsx";
import store from "@/store";
import moment from 'moment';
import authApi from "../api/AuthApi";

export default {
    methods: {
        goPage(route, lang) {
            this.changeLanguage(lang);
            this.$router.push(route);
        },
        changeLanguage(lang) {
            this.storageSave("base/currentLanguage", lang);
            this.vuexFromStorage("base/currentLanguage")
            // this.toast("dari method " + this.storageLoad("base/currentLanguage"))

        },
        /* 
        * to get data from local storage, and set it to vuex 
        * format: this.vuexFromStorage("module/key") 
        */
        vuexFromStorage(key) {
            /* ini dibutuhkan untuk ambil baseURL dari file js, jangan dihapus */
            if (key === "auth/baseURL") {
                localStorage.setItem("auth/baseURL", this.storageLoad(key) ?? "");
            }
            store.commit(key, this.storageLoad(key) ?? "");
            // console.log(key)
            // console.log(this.storageLoad(key))
        },
        getErrorMessage(error) {
            if (error?.response?.data?.message) return error?.response?.data?.message;
            else if (error?.message) return error?.message;
            else return error;
        },

        getErrorStatusCode(error) {
            if (error?.response?.status) return error?.response?.status;
            else return "999";
        },

        showErrorMessage(error) {
            this.$root.snackbar.showError(error);
        },

        today() {
            return new Date().toISOString().slice(0, 10);
            // return new Date().getTime() * 10000 + 621355968000000000;
        },

        round(number, digits = 2) {
            if (isNaN(number) || isNaN(digits)) return "";
            return parseFloat(number.toFixed(digits));
        },

        formatDate(date) {
            if (!date) return "";
            // date = new Date(date);
            // return date.toLocaleDateString("id-ID");
            const timestamp = parseInt(date.replace(/\/Date\((\d+)\)\//, '$1'));
            return moment(timestamp).format('DD/MM/YYYY');
        },
        ReportDate(dateString) {
            // Membuat objek Date dari string input
            const date = new Date(dateString);
          
            // Mendapatkan komponen tanggal dari objek Date
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Januari adalah 0
            const year = date.getFullYear();
          
            // Mendapatkan komponen waktu dari objek Date
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
          
            // Menggabungkan semua komponen untuk membentuk string tanggal yang diformat
            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        },         
        formatNumber(number) {
            if (isNaN(number)) return "0";
            if (number) return Number(number).toLocaleString("id-ID");
            return "0";
        },

        parseDate(date) {
            return date.substring(0, 10);
        },

        parseNumber(number) {
            if (isNaN(number)) return 0;

            if (number) return Number(number);
            return 0;
        },

        encryptString(text) {
            if (!text) return "";
            const x = btoa(unescape(encodeURIComponent(text)));
            const y = x.split("").reverse().join("");
            const z = btoa(y);
            return z;
        },

        decryptString(text) {
            if (!text) return "";
            const z = decodeURIComponent(escape(atob(text)));
            const y = z.split("").reverse().join("");
            const x = atob(y);
            return x;
        },

        /* format: this.storageSave("module/key", value) */
        storageSave(key, value) {
            const json = JSON.stringify(value);
            const encryptedValue = this.encryptString(json);
            const encryptedKey = this.encryptString(key);
            localStorage.setItem(encryptedKey, encryptedValue);
        },

        /* format: this.storageLoad("module/key") */
        storageLoad(key) {
            try {
                const encryptedKey = this.encryptString(key);
                const encryptedValue = localStorage.getItem(encryptedKey);
                const json = this.decryptString(encryptedValue);
                return JSON.parse(json);
            } catch {
                return null;
            }
        },

        storageRemove(key) {
            const encryptedKey = this.encryptString(key);
            localStorage.removeItem(encryptedKey);
        },

        sessionSave(key, value) {
            const json = JSON.stringify(value);
            sessionStorage.setItem(key, json);
        },

        sessionLoad(key) {
            try {
                const json = sessionStorage.getItem(key);
                return JSON.parse(json);
            } catch {
                return null;
            }
        },

        sessionRemove(key) {
            sessionStorage.removeItem(key);
        },

        downloadExcel(datasource, filename) {
            if (datasource) {
                const data = XLSX.utils.json_to_sheet(datasource);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, data, "Data");
                XLSX.writeFile(wb, filename);
            }
        },

        downloadFile(url, fileName) {
            const a = document.createElement("a");
            a.href = url;
            a.setAttribute("download", fileName);
            a.style.visibility = "hidden";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },

        downloadBlob(content, fileName) {
            const a = document.createElement("a");
            const objectURL = URL.createObjectURL(new Blob([content]));
            a.setAttribute("href", objectURL)
            a.setAttribute("download", fileName);
            a.style.visibility = "hidden";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },

        createRecord(datasource, resource) {
            datasource.push(resource);
        },

        updateRecord(datasource, resource) {
            let item = datasource.filter(x => x.id === resource.id)[0];
            const index = datasource.indexOf(item);
            datasource.splice(index, 1, resource);
        },

        deleteRecord(datasource, id) {
            const item = datasource.filter(x => x.id === id)[0];
            const index = datasource.indexOf(item);
            datasource.splice(index, 1);
        },
        toast(message, type) {
            if (!type) type = "default"
            this.$toast.open({
                message: message,
                type: type,
                duration: 5000,
                dismissible: true
            });
        },
        async generateUniqueId(employeeId) {
            // this.toast("EmployeeId is " + JSON.stringify(employeeId))

            if (!employeeId) {
                employeeId = await authApi.getIdFromToken();
            }
            // this.toast("EmployeeId is " + employeeId)
            const characters = 'CDEFHKLMNPQRTUVWXYZ0123456789';
            let uniqueId = '' + employeeId.toString(16).toUpperCase();

            //const randomLetter = Math.floor(Math.random() * characters.length) + 7;
            //uniqueId += characters[randomLetter];

            while (uniqueId.length < 8) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                uniqueId += characters[randomIndex];
            }
            const splitThree = uniqueId.match(/.{1,1}/g);
            const result = splitThree.join(' ');
            return result;
        }
    },
};
