<template>
  <v-text-field
      v-model.number="cmpValue"
      class="number-input"
      type="number"
      v-bind="properties"
      v-bind:backgroundColor="backgroundColor"
      v-bind:clearable="clearable"
      v-bind:dense="dense"
      v-bind:disabled="disabled"
      v-bind:error="error"
      v-bind:error-messages="errorMessages"
      v-bind:hide-details="hideDetails"
      v-bind:label="label"
      v-bind:outlined="outlined"
      v-bind:placeholder="placeholder"
      v-bind:readonly="readonly"
      v-bind:rules="rules"
      @change="change"
      @focus="$event.target.select()"
      @keypress="keyPress"
      @keyup="keyUp"
  ></v-text-field>
</template>

<script>
export default {
  model: {prop: "value", event: "input"},
  props: {
    value: {
      // type: String,
      type: [String, Number],
      default: "0",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    rules: {
      type: [Array, String],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    valueWhenIsEmpty: {
      type: String,
      default: "0", // "0" or "" or null
    },
    // Other v-text-field properties
    properties: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({}),
  computed: {
    cmpValue: {
      get: function () {
        return this.value !== null && this.value !== "" && !isNaN(this.value)
            ? this.value.toString()
            : this.valueWhenIsEmpty;
      },
      set: function (newValue) {
        if (newValue && !isNaN(newValue)) this.$emit("input", newValue);
        else this.$emit("input", this.valueWhenIsEmpty);
      },
    },
  },
  methods: {
    keyPress($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 44 || keyCode > 57) && keyCode != 190) {
        $event.preventDefault();
      }
    },
    keyUp(event) {
      this.$emit("keyup", event);
    },
    change(event) {
      this.$emit("change", event);
    },
  },
};
</script>

<style>
.number-input input {
  text-align: right;
}

.number-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input input[type="number"] {
  -moz-appearance: textfield;
}
</style>
