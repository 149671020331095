<template>
    <v-container fluid>
        <h2 class="my-4 custom-text-9-id">Company Structure</h2>
        <v-row justify="center">
            <v-col cols="12" md="9">
                <v-card flat>
                    <v-col cols="12" sm="12" style="text-align: center;">
                        <img src="/images/OS.jpg" style="max-width: 80%; height: auto;">
                    </v-col>
                </v-card>
            </v-col>
        </v-row>

        <h2 class="my-4 custom-text-9-id">Sending Scheme</h2>
        <v-row justify="center">
            <v-col cols="12" md="9">

                <v-card flat>
                    <v-col cols="12" sm="12" style="text-align: center;">
                        <img src="/images/SO.jpg" style="max-width: 80%; height: auto;">
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <h2 class="my-4 custom-text-9-id">Sending Result</h2>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-card class="chart-card" flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-select v-model="selectedYearForChart" :items="years" label="Year" outlined
                                    @change="generateChartData"></v-select>
                            </v-col>
                        </v-row>
                        <div v-if="chartData.length > 0" class="chart-container">
                            <div v-for="(data, index) in chartData" :key="index" class="chart-row">
                                <div class="chart-label">{{ months[index] }}</div>
                                <div class="chart-bar">
                                    <div :style="{ width: (data.value / 200 * 100) + '%' }" class="bar">
                                        <span class="bar-value">{{ data.value }}</span>
                                    </div>
                                </div>
                                <div class="chart-value">{{ data.value }}</div>
                            </div>
                        </div>
                        <div v-else class="no-data">No Data Available.</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <h2 class="my-4 custom-text-9-id">Response When Problems Occur</h2>
        <v-row justify="center">
            <v-col cols="12" md="9">

                <v-card flat>
                    <v-col cols="12" sm="12" style="text-align: center;">
                        <img src="/images/Respon.jpg" style="max-width: 80%; height: auto;">
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <!-- <h2 class="my-4 custom-text-4">Professional Skill</h2>
        <h2 class="my-4 custom-text-4">Technical Intern Trainee</h2> -->
    </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
    async mounted() {
        await auditrail.create({
            action: this.$route.path
        });
        this.generateChartData();
    },
    data: () => ({
        selectedYear: null,
        selectedMonth: null,
        personNumber: null,
        selectedYearForChart: 2024,
        years: [2021, 2022, 2023, 2024],
        months: [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ],
        personData: {
            '2021-January': 59, '2021-February': 22, '2021-March': 16, '2021-April': 40, '2021-May': 42, '2021-June': 27,
            '2021-July': 13, '2021-August': 78, '2021-September': 86, '2021-October': 66, '2021-November': 9, '2021-December': 36,

            '2022-January': 52, '2022-February': 72, '2022-March': 63, '2022-April': 10, '2022-May': 23, '2022-June': 27,
            '2022-July': 43, '2022-August': 75, '2022-September': 30, '2022-October': 89, '2022-November': 86, '2022-December': 77,

            '2023-January': 16, '2023-February': 42, '2023-March': 94, '2023-April': 13, '2023-May': 18, '2023-June': 100,
            '2023-July': 57, '2023-August': 77, '2023-September': 87, '2023-October': 33, '2023-November': 13, '2023-December': 84,

            '2024-January': 81, '2024-February': 16, '2024-March': 76, '2024-April': 100, '2024-May': 79, '2024-June': 69,
            '2024-July': 72, '2024-August': 27, '2024-September': 25, '2024-October': 7, '2024-November': 82, '2024-December': 20,
        },
        chartData: []
    }),

    methods: {
        onYearChange() {
            this.selectedMonth = null;
            this.personNumber = null;
            this.chartData = [];
        },
        onMonthChange() {
            const key = `${this.selectedYearForPerson}-${this.selectedMonth}`;
            this.personNumber = this.personData[key] || 'Data tidak tersedia';
        },
        generateChartData() {
            this.chartData = [];
            if (!this.selectedYearForChart) return;

            this.months.forEach((month) => {
                const value = this.personData[`${this.selectedYearForChart}-${month}`] || 0;
                this.chartData.push({
                    month: month,
                    value: value,
                    color: this.getRandomColor()
                });
            });
        },
        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }
    }
};
</script>

<style>
.chart-card {
    overflow-x: auto;
    padding: 16px;
}

.chart-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.chart-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.chart-label {
    width: 80px;
    text-align: left;
    margin-right: 10px;
}

.chart-bar {
    flex-grow: 1;
    background-color: #e0e0e0;
    position: relative;
}

.bar {
    height: 100%;
    background-color: #12486B;
    display: flex;
    align-items: center;
}

.bar-value {
    color: white;
    margin-left: 5px;
}

.chart-value {
    width: 50px;
    text-align: left;
    margin-left: 10px;
}

.no-data {
    text-align: center;
    color: red;
}

.person-info {
    font-weight: bold;
}

.custom-text {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
}

.company-profile-table th,
.company-profile-table td {
    border: 1px solid black;
}

.company-profile-table thead .black-header {
    background-color: #000;
    color: #fff;
}


.company-profile-table {
    border-collapse: collapse;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}
</style>
