import http from "./http";

const AuthApi = {
    endPoint: "api/user/login",
    async login(resource) {
        try {
            var headers = {
                "Access-Control-Allow-Origin" : "https://lpkaishingroup.azurewebsites.net/"
            }
            const response = await http.post(this.endPoint, resource, headers);
            return response.data;
        }
        catch (ex) {
            throw Error(ex.response.data.message);
        }
    },
    async getIdFromToken(){
        const response = await http.post(`apiuser/GetIdFromToken`);
        return response.data;
    },

    async refreshToken(resource) {
        const response = await http.post("apiuser/refresh-token", resource);
        return response.data;
    }
};

export default AuthApi;