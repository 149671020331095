<template>
  <v-container fluid>
    <v-layout row justify-center mt-5>
      <v-flex xs12 sm12 md9>
        <v-carousel height="500" cycle :interval="3000" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(item, i) in sliders" :key="i" :src="item.src" reverse-transition="fade-transition"
            transition="fade-transition" align-start>
            <v-container fluid>
              <v-row class="fill-height pa-5">
                <v-col cols="12" md="6" class="d-flex flex-column justify-center align-start">
                  <transition name="fade" mode="out-in">
                    <div key="item.title" class="text-container" v-if="i === 1 || i === 2">
                      <h3 class="mb-2 custom-text-7">{{ item.title }}</h3>
                      <p class="custom-text-8">{{ item.description }}</p>
                      <v-btn text v-if="i === 1" class="mt-4 custom-text-8" @click="goPage('/login')">ログイン
                        &gt;&gt;&gt;</v-btn>
                      <v-btn text v-if="i === 2" class="mt-4 custom-text-8" @click="goPage('/reservation')">今すぐ登録
                        &gt;&gt;&gt;</v-btn>
                    </div>
                  </transition>
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-center align-center">
                  <!-- Gambar atau konten lain untuk sisi kanan -->
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
    <div style="height: 2cm;"></div>
    <v-container fluid>
      <v-row align="center" justify="center" class="my-4">
        <!-- Kolom untuk Logo -->
        <v-col cols="12" sm="3" class="text-center">
          <img src="/images/logo-bordered.png" style="max-width: 100%; height: auto;">
        </v-col>

        <!-- Kolom untuk Judul/Sub Judul dan Teks Tambahan -->
        <v-col cols="12" sm="6" class="text-center">
          <div>
            <h1 class="custom-text-7 black--text">LPK AISHIN INDONESIA</h1>
            <h2 class="custom-text-3 black--text">インドネシアからの派遣機関</h2>
            <p class="custom-text-3 black--text">参加手順、明るい未来 インドネシアと日本が夢を築く</p>

          </div>
        </v-col>
      </v-row>

      <!-- Baris untuk Motto -->
      <v-row justify="center" class="my-4">
        <v-col cols="12" md="8">
          <v-row justify="center">
            <v-col cols="12" sm="6" md="3" v-for="(motto, index) in mottos" :key="index">
              <v-card class="d-flex flex-column justify-center align-center pa-5 motto-card">
                <v-icon size="48" color="#ffffff">{{ motto.icon }}</v-icon>
                <div class="motto-text">{{ motto.text }}</div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div class="my-4 custom-text-9" style="margin-top: 100px;">LPKアイシンインドネシアとは ?</div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <v-row align="center">

            <!-- Kolom untuk foto -->
            <v-col cols="12" md="4" style="text-align: center;">
              <img src="/images/teachers/berman.webp" style="max-width: 60%; height: auto;">
            </v-col>

            <!-- Kolom untuk teks -->
            <v-col cols="12" md="8">

              <v-card-text class="custom-text-2">
                <p>LPK アイシン•インドネシア［LPK AISHIN
                  INDONESIA］は、インドネシア労働省から認可された送出機関として、日本とインドネシア両国の法制度に準拠し、適正•適格•遵法を厳守した送り出し事業を展開しています。</p>
                <p>インドネシア国内では 10 年以上の労働者派遣事業の実績があり、インドネシアに進出された数多くの日系企業様に、契約社員や研修生を採用して頂いております。</p>
                <p>また、インドネシア国内の他の送出機関や日本語教育機関との提携、各自治体との
                  連携から、あらゆる企業の要望に合致した人材を、迅速に手配することが可能です。我々は、企業様の皆様に安心、信頼される送出機関として、日々精進に努めてまい
                  ります。</p>
              </v-card-text>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-layout row wrap>
      <v-flex xs12>
        <div class="custom-text-9" style="margin-bottom: 60px;">適法を維持するための継続的活動</div>
      </v-flex>
      <v-card flat>
        <v-layout row wrap>
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 sm3>
              <v-card flat class="d-flex flex-column align-center justify-center">
                <v-img src="/images/Home/Home-1.webp" style="max-width: 80%; max-height: 250px;"
                  class="grey lighten-2"></v-img>
                <v-card-text class="custom-text">労働局の許認可審査</v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 sm3>
              <v-card flat class="d-flex flex-column align-center justify-center">
                <v-img src="/images/Home/Home-2.webp" style="max-width: 80%; max-height: 200px;"
                  class="grey lighten-2"></v-img>
                <v-card-text class="custom-text">学生への
                  聞き取り風景
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card>
    </v-layout>

    <div style="height: 2cm;"></div>
    <div class="my-4 custom-text-9" style="margin-top: 100px;">卒業生からの声</div>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="mx-auto" flat>
          <v-carousel v-model="carousel" hide-delimiters show-arrows cycle height="400px" class="elevation-2">
            <v-carousel-item v-for="(feedback, index) in feedbacks" :key="index">
              <v-row no-gutters align="center" class="fill-height">
                <v-col cols="4" class="d-flex justify-center align-center">
                  <v-avatar size="120" class="elevation-6">
                    <!-- Contoh menggunakan gambar dari placeholder.com sebagai alternatif -->
                    <v-img :src="feedback.image" class="br-20"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="8" class="d-flex align-center">
                  <div class="feedback-text">{{ feedback.text }}</div>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import auditrail from "../../../api/AuditrailApi";

export default {
  async mounted() {
    await auditrail.create({
      action: this.$route.path
    });
  },
  data() {
    return {
      mottos: [
        { text: "熱意", icon: "mdi-fire" },
        { text: "忍耐", icon: "mdi-timer-sand" },
        { text: "謙遜", icon: "mdi-hands-pray" },
        { text: "野心", icon: "mdi-flag-checkered" },
        { text: "信頼", icon: "mdi-handshake" },
        { text: "無限", icon: "mdi-infinity" }, 
        { text: "挑戦", icon: "mdi-trophy" }, 
      ],
      sliders: [
        {
          src: '/images/Stock/2.webp',
          title: '',
          description: ''
        },
        {
          src: '/images/Stock/1.1.webp',
          title: 'Japan-Indonesia',
          description: '有望なインドネシアの労働力の展望を解き放ち、貴社の企業のための世界を探検しましょう。旅を始めましょう-ログインして接続します。',
        },
        {
          src: '/images/Stock/2.2.webp',
          title: '日本が待っています: グローバルキャリアを開始しましょう',
          description: '日本でキャリアを築くチャンスを掴みましょう。国際的な成功への旅が始まる場所、LPKアイシンに参加してください。',
        },
      ],

      carousel: 0,
      feedbacks: [
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINは私のキャリアの発展の礎となっています。彼らのガイダンスは類を見ないです。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINが提供した機会は私の職業生活を変えました。成長の真のパートナーです。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINからの素晴らしいサポート！彼らの卓越したコミットメントは彼らのすべての行動に明らかです。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINと一緒に、私は仕事以上のものを見つけました。日本でのキャリアパスを見つけました。永遠に感謝しています。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'プロフェッショナルでサポートがあり、献身的です。LPK AISHINは日本で働きたい人々のための灯台として目立ちます。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINのチームはあなたの成功を本当に心から心配しています。彼らのサポートは私にとって重要なものです。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINとの仕事はゲームチェンジャーでした。異文化を橋渡しする彼らの専門知識は印象的です。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINは単なる組織ではありません。これはあなたの日本での夢を実現するためのゲートウェイです。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINのプロフェッショナリズムと献身は類を見ないです。日本で働きたい人には強くお勧めします。'
        },
        {
          image: 'https://via.placeholder.com/150', text: 'LPK AISHINを見つけることは私のキャリアの転機でした。彼らのガイダンスが私の日本での仕事への移行をスムーズにしました。'
        },
        {
          image: 'https://via.placeholder.com/150', text: '最初から、LPK AISHINは貴重なパートナーでした。彼らの専門知識とサポートが私の日本での成功の鍵でした。'
        }
      ],

    };
  },
  methods: {
    goPage(path) {
      this.$router.push(path); // Memastikan kamu sudah mengonfigurasi vue-router
    },
    next() {
      const next = this.carousel + 1;
      this.carousel = next % this.feedbacks.length;
    },
    prev() {
      const prev = this.carousel - 1;
      this.carousel = prev >= 0 ? prev : this.feedbacks.length - 1;
    },
  },
};
</script>
<style scoped>
.feedback-text {
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
}

.v-carousel {
  border: 2px solid #E0E0E0;
  border-radius: 20px;
}

.text-container {
  padding-top: 50px;
  padding-left: 50px;
}

.v-btn {
  text-decoration: underline;
  padding-left: 0;
}

.custom-text-7 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 42px;
  color: white;
  text-align: justify;
}

.custom-text-8 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  text-align: justify;
}

.custom-text-9 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #900C27;
  text-align: center;
  padding-bottom: 10px;
}

.carousel-container {
  max-width: 100%;
  padding-top: 100px;
}

.v-carousel {
  margin: 0 auto;
  padding-top: 10px;
}

.motto-card {
  height: 200px;
  /* Tentukan tinggi tetap untuk kotak */
  width: 100%;
  /* Agar lebar mengisi kolom */
  margin-bottom: 16px;
  /* Jarak antar kotak */
  background-color: #31363F;
  color: white; /* Agar teksnya terlihat di atas background warna maroon */
}

.motto-year {
  font-size: 24px;
  /* Ukuran teks untuk tahun */
  color: black;
  /* Warna teks untuk tahun */
  margin-bottom: 8px;
  /* Jarak antara tahun dan teks motto */
}

.motto-text {
  font-size: 18px;
  color: white; /* Agar teksnya terlihat di atas background warna maroon */
  text-transform: uppercase;
  padding: 0 10px;
}

/* Menambahkan media query untuk responsivitas */
@media (max-width: 600px) {
  .motto-card {
    height: 150px;
    /* Kotak lebih kecil untuk layar yang lebih kecil */
  }
}

.motto-card {
  transition: all 0.3s ease;
}

.motto-card:hover {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
  transform: scale(1.05);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active di <2.1.8 */
  {
  opacity: 0;
}
</style>

