<template>
  <v-main>
    <v-container>
      <v-card-title class="custom-text-4">Create Graduation Data</v-card-title>
      <v-row>
        <v-col cols="12">
          <v-card class="mt-4 mb-4 pa-5">
            <v-spacer></v-spacer>

            <v-form @submit.prevent="submitForm">
              <!-- Nama -->
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.name" label="Name"></v-text-field>
                </v-col>
              </v-row>
              <!-- Tempat Lahir -->
              <v-row>
                <v-col cols="4">
                  <v-text-field outlined v-model="newItem.birthPlace" label="Born Place"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field outlined v-model="newItem.birthDay" label="Born Date" type="date"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select outlined v-model="newItem.gender" :items="genderOptions" label="Gender"></v-select>
                </v-col>
              </v-row>

              <!-- Agama -->
              <v-row>
                <v-col cols="4">
                  <v-select outlined v-model="newItem.religion" :items="religionOptions" label="Religion"></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field outlined v-model="newItem.phone" label="Phone Number"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field outlined v-model="newItem.email" label="Email"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.address" label="Address"></v-text-field>
                </v-col>
              </v-row>

              <!-- Pendidikan Terakhir -->
              <v-row>
                <v-col cols="12">
                  <v-select outlined v-model="newItem.education" :items="educationOptions"
                    label="Education"></v-select>
                </v-col>
              </v-row>

              <!-- Nama Perusahaan di Jepang -->
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.companyNameJapan"
                    label="Company Name at Japan"></v-text-field>
                </v-col>
              </v-row>

              <!-- Alamat Perusahaan di Jepang -->
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.companyAddressJapan"
                    label="Company Address at Japan"></v-text-field>
                </v-col>
              </v-row>

              <!-- Tanggal Pergi ke Jepang -->
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.dateToJapan" label="Date to Japan"
                    type="date"></v-text-field>
                </v-col>
              </v-row>

              <!-- Sertifikat JLPT -->
              <v-row>
                <v-col cols="12">
                  <v-select outlined v-model="newItem.jlptCertificate" :items="jlptOptions"
                    label="JLPT Certificate"></v-select>
                </v-col>
              </v-row>

              <!-- Skills -->
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.skills" label="Skills"></v-text-field>
                </v-col>
              </v-row>

              <!-- LPK Asal -->
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.LPKOrigin" label="LPK Collaborator"></v-text-field>
                </v-col>
              </v-row>

              <!-- Tanggal Bergabung dengan LPK -->
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined v-model="newItem.DateJoinLPK" label="Affiliated LPK"
                    type="date"></v-text-field>
                </v-col>
              </v-row>

              <!-- Upload Foto -->
              <v-row>
                <v-col cols="12">
                  <v-file-input outlined label="Upload Foto"></v-file-input>
                </v-col>
              </v-row>

              <!-- Tombol Submit -->
              <v-row>
                <v-col cols="12">
                  <v-btn type="submit" color="primary">Add Data</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import employee from "../../api/EmployeeApi";
import auditrail from "../../api/AuditrailApi";
import Swal from 'sweetalert2';

export default {
  async mounted() {
    await auditrail.create({
      action: this.$route.path
    });
  },
  data() {
    return {
      newItem: {
        name: '',
        birthPlace: '',
        birthDay: null,
        gender: '',
        religion: '',
        phone: '',
        email: '',
        address: '',
        education: '',
        companyNameJapan: '',
        companyAddressJapan: '',
        dateToJapan: null,
        jlptCertificate: '',
        skills: '',
        LPKOrigin: '',
        DateJoinLPK: null,
      },
      genderOptions: ['Laki-laki', 'Perempuan'],
      religionOptions: ['Islam', 'Kristen', 'Katholik', 'Hindu', 'Buddha', 'Konghucu', 'Atheis', 'Others'],
      jlptOptions: ['N5', 'N4', 'N3', 'N2', 'N1'],
      educationOptions: ['SD', 'SMP', 'SMA', 'Diploma', 'S1', 'S2', 'S3'],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    async submitForm() {
      this.mode = 'create';
      await this.save();
    },
    async save() {
      try {
        if (this.mode === "create") {
          let result = await employee.create(this.newItem);
          if (result.ErrorMessage) {
            throw new TypeError(result.ErrorMessage);
          }
        } else if (this.mode === "update") {
          await employee.update(this.newItem);
        }

        Swal.fire({
          title: 'Success!',
          text: 'Member has been added successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.value) {
            this.$router.push("/client/profile");
          }
        });
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  }
};
</script>

<style scoped>
.v-footer {
  color: white;
}

/* Style untuk v-card */
.v-card {
  margin-top: 1cm;
  margin-bottom: 1cm;
}
</style>