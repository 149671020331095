<template>
    <v-container fluid>
        <div class="my-4 custom-text-9-cp">COMPANY PHILOSOPHY</div>
        <v-row>
            <v-col cols="12" sm="4" class="text-right">
                <v-avatar size="250px" style="padding-right: 20px; border-radius: 12px;">
                    <img src="/images/teachers/berman.webp" alt="President-Director Berman Nainggolan"
                        style="border-radius: 12px;">
                </v-avatar>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card flat>
                    <v-card-text class="custom-text-2 text-justify">
                        <p> "Indonesian youth have dreams. The first is to work... In Indonesia, where the unemployment
                            rate among people under the age of 25 reaches nearly 20%, many young generations want to
                            work.</p>

                        <p> The second is to realize my dream... Traveling around Japan, rich in nature, visiting sacred
                            anime places, and buying goods in Akihabara.</p>

                        <p> Japanese anime culture has penetrated Indonesian youth. And lastly, "building the future"...
                            Young people who go to Japan dream of finding a job or starting a business in Japanese
                            companies based on their work experience in Japan.</p>

                        <p> The management philosophy of LPK AISHIN INDONESIA is to help society realize their dreams.
                            Our mission is to pave the way for a secure future for as many young generations as
                            possible."</p>

                        <p> - Berman Nainggolan</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <div class="custom-text-9">COMPANY EXECUTIVE</div>
        <v-row class="my-4">
            <v-col v-for="person in people" :key="person.name" cols="12" md="12" class="text-center">
                <v-card flat>
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="3">
                            <v-avatar size="150" class="ma-3" style="border-radius: 12px;">
                                <img :src="person.photoUrl" alt="Photo" style="border-radius: 12px;">
                            </v-avatar>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-text class="text-left">
                                <div class="mb-1 custom-text-3 text-justify">{{ person.name }}</div>
                                <div class="mb-1 custom-text-3 text-justify">{{ person.position }}</div>
                                <div class="mb-1 custom-text-2 text-justify">{{ person.description }}</div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import auditrail from "../../../api/AuditrailApi";

export default {
    async mounted() {
        await auditrail.create({
            action: this.$route.path
        });
    },
    data() {
        return {
            people: [
                {
                    photoUrl: '/images/teachers/berman.webp',
                    name: 'Berman Nainggolan',
                    position: 'Chairman',
                    description: 'Berman Nainggolan, a legal expert with degrees in SH., MH., CLA, has dedicated his career to advocating for justice. As the Chairman of LPK AISHIN INDONESIA, Berman not only carries the responsibility of leadership but also defines the company\'s vision with a strong sense of justice. Certified JLPT N1, his proficiency in Japanese strengthens cross-cultural collaborations in achieving common goals.'
                },
                {
                    photoUrl: '/images/teachers/kato.webp',
                    name: 'Kousuke Kato',
                    position: 'Director of Representative EBT Co., Ltd.',
                    description: 'Kousuke Kato, the Director of Representative EBT Co., Ltd., is the bridge between Japan and Indonesia. With a background in international relations and extensive experience in facilitating cross-border cooperation, Kousuke embodies the company\'s vision with unmatched commitment. As a manager of strategic projects, his ability to turn vision into reality has garnered widespread recognition.'
                },
                {
                    photoUrl: '/images/teachers/yosua.webp',
                    name: 'Yosua Parmes Sitorus',
                    position: 'Coordinator',
                    description: 'Yosua Parmes Sitorus, a coordinator with a sharp vision and deep expertise. A graduate of the University of Tokyo and certified JLPT N1, Yosua brings essential cross-cultural communication skills to his role. In leading various initiatives, Yosua is committed to building strong networks and ensuring mutual success for all parties involved.'
                },
            ],
        };
    }
};
</script>

<style>

.custom-text-3 {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #12486B;
    text-align: justify;
}
.custom-text-2 {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
}
.custom-text-9 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 60px;
  color: #12486B;
  text-align: center;
  padding-bottom: 10px;
  line-height: 1.5;
}
.custom-text-9-cp {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-size: 54px;
  color: #12486B;
  text-align: center;
  padding-bottom: 10px;
}
</style>
