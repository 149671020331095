<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer> 
        <!-- Setiap v-card sebagai item navigasi yang dapat diklik -->
        <v-col cols="12" md="2" v-for="(item, index) in navItems" :key="index">
          <v-card
            :class="['hoverable-card', 'align-self-center', { 'hover-active': hoverIndex === index }]"
            @mouseover="hoverIndex = index"
            @mouseleave="hoverIndex = -1"
            @click="setLastPage(item.route)"
            class="mb-4"
            elevation="2"
            style="cursor: pointer; border-radius: 15px; transition: transform 0.3s ease-in-out, background-color 0.3s ease;">
            <v-card-text class="text-center">
              <img :src="item.imgSrc" alt="Icon" class="card-icon"> <!-- Menambahkan class card-icon -->
              <div class="custom-text-9">{{ item.title }}</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer></v-spacer> 
      </v-row>
    </v-container>
  </v-main>
</template>


  
<script>
export default {
  data() {
  return {
    hoverIndex: -1,  // Untuk melacak kartu mana yang di-hover
    navItemsClient :[
    { title: 'List Of Graduates', imgSrc: '/images/svg/hamburger-menu-svgrepo-com.svg', route: '/lpkmember' },
    ],
    navItemsAdministrator :[
    { title: 'List Of Graduates', imgSrc: '/images/svg/hamburger-menu-svgrepo-com.svg', route: '/lpkmember' },
      { title: 'Account List', imgSrc: '/images/svg/cardholder-svgrepo-com.svg', route: '/clientlist' },
      { title: 'Add Graduates Data', imgSrc: '/images/svg/accessibility-svgrepo-com.svg', route: '/addmember' },
      { title: 'Create Account', imgSrc: '/images/svg/user-svgrepo-com.svg', route: '/regclient' },
      { title: 'Activity Report', imgSrc: '/images/svg/notebook-svgrepo-com.svg', route: '/auditrailreport' }
    ],
    navItems: [
    ]
  };
},
mounted(){    
  this.navItems = this.navItemsClient;
  let identity = this.storageLoad("auth/identity");
  if(identity){
    if(identity.isAdmin == "administrator"){
      this.navItems = this.navItemsAdministrator;
    }
  }
},

  methods: {
    setLastPage(href) {
      this.$router.push(href);
    }
  }
};
</script>

<style scoped>
.v-col {
  display: flex;
  justify-content: center; /* Pusatkan kartu secara horizontal */
  align-items: center; /* Pusatkan kartu secara vertikal */
  height: 100%; /* Menetapkan tinggi penuh untuk mendukung vertical centering */
}

.hoverable-card {
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;
  border-radius: 15px;
  height: 150px;
}

.hoverable-card:hover {
  transform: scale(1.05);
  background-color: #f0f0f0;
}

.card-icon {
  width: 50px; /* Menetapkan lebar ikon */
  height: 50px; /* Menetapkan tinggi ikon */
  margin-bottom: 10px; /* Jarak antara ikon dan judul */
}

.custom-text-9 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 21px;
  color: #31363F;
  text-align: center;
  padding-bottom: 10px;
}
</style>
